import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import DataTable from '../../../../../components/common/dataTable/DataTable';
import Title from '../../../components/Title';
import Body from '../../../layout/Body';
import { ReactComponent as EditIcon } from '../../../../../assets/images/icons/editIcon.svg';
import { ReactComponent as ViewAnsIcon } from '../../../../../assets/images/icons/answerIcon.svg';
import { setColor, setFont } from '../../../../../assets/styles/styles';
import Button from '../../../../../components/common/button/Button';
import Modal from '../../../components/Modal';
import { getFormInfoById, getFormQuestionTypes } from '../api/surveysController';
import { formatDateTimeWithTimeZone } from '../../../../../utils/generalUtils';
import Typography from '../../../../../components/common/text/Typography';
import { ReactComponent as ChevronIcon } from '../../../../../assets/images/icons/Chevron.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/icons/deleteIcon.svg';
import DeleteModal from './DeleteModal';
import EditModal from './Update/EditModal';
import AddModal from './Add/AddModal';
import { DEFAULT_QUERY_OPTIONS } from '../../../../../constants';

const QuestionOptionsTable = () => {
    const navigate = useNavigate();
    const { surveyId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [totalNumber, setTotalNumber] = useState(0);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleRows, setVisibleRows] = useState([]);
    const [questionTypesData, setQuestionTypesData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const ROWS_PER_PAGE = 5;

    const handleBack = () => {
        navigate(`/console/surveys`);
    };

    const { data: formsInfo, error: formsErr , isLoading: formsLoading } = useQuery(
        ['forms', surveyId], 
        () => getFormInfoById(surveyId), 
        {
            enabled: !!surveyId, 
            ...DEFAULT_QUERY_OPTIONS(), 
        }
    );

    const { data: formQuestionTypes, error: formQuestionErr , isLoading: formQuestionLoading } = 
        useQuery(['formQueTypes'],
            getFormQuestionTypes(),
        {
            ...DEFAULT_QUERY_OPTIONS(300000), 
        }
    );

    useEffect(() => {
        if (formsInfo) {
            const data = formsInfo.questions;
            setTotalNumber(data.length);
            setTotalPages(Math.ceil(data.length / ROWS_PER_PAGE));
            setVisibleRows(data.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE));
        }
        if(formQuestionTypes && formQuestionTypes.length > 0) {
            const makeDropDownData = formQuestionTypes.map((item) => {
                return {
                    value: item.formQuestionTypeId,
                    label: item.formQuestionTypeName
                };
            });
            setQuestionTypesData(makeDropDownData);
        }
    }, [formsInfo, formQuestionTypes]);

    const openModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };

    const handleAnswerClick = (row) => {
        setSelectedRow(row);
        navigate(`/console/surveys/${surveyId}/questions/${row.id}/options`);
    };

    const handleDeleteClick = (row) => {
        setSelectedRow(row);
        setIsModalDeleteOpen(true);
    }

    const handleEditClick = (row) => {
        setSelectedRow(row);
        setIsModalEditOpen(true);
    }

    const renderRow = (question) => {
        questionTypesData.forEach((item) => {
            if (question.type === item.value) {
                question.type = `${item.value}-${item.label}`;
            }
        });
        const buttonAns = (
            <StyledViewAnsIcon onClick={() => handleAnswerClick(question)} />
        );
        const buttonEdit = (
            <StyledEditIcon onClick={() => handleEditClick(question)} />
        );
        const buttonDelete = (
            <StyledDeleteIcon onClick={() => handleDeleteClick(question)} />
        );
        return [
            question.id,
            question.name || 'N/A', 
            question.body || 'N/A',
            question.type || 'N/A',
            <>{buttonAns}</>,
            <>{buttonEdit}</>,
            <>{buttonDelete}</>,
        ];
    };

    const addQuestionClick = () => {
        setIsModalAddOpen(true);
    }

    return (
        <>
            <CardMainTable>
                <TableRow>
                    <Title 
                        title={'SURVEY QUESTIONS'} 
                        description={'Manage all questions for surveys/forms in Intraverse.'} 
                    />

                    <TableColumn>
                        <Button
                            variant={'OutlinedButton'}
                            label={'Add Question'}
                            margin={'2rem 0 0 0'}
                            onClick={addQuestionClick}
                        />  
                    </TableColumn>
                </TableRow>
            </CardMainTable>

            
            <UserConsoleText style={{ marginBottom: '1.5rem' }} onClick={handleBack}>
                <StyledIconPrev /> BACK TO SURVEYS TABLE
            </UserConsoleText>

            <Body isLoading={isLoading}>
                <>
                    {visibleRows && (
                        <>
                            <CardTable>
                                <TableRow>
                                    <TableColumn>
                                        <Typography variant="PinkText">{name}</Typography>
                                        {surveyId}
                                    </TableColumn>
                                </TableRow>
                            </CardTable>
                            <DataTable
                                headers={['QUESTION ID', 'SEQUENCE', 'QUESTION', 'TYPE', '']}
                                data={visibleRows}
                                renderRow={renderRow}
                                openModal={openModal}
                                isModalOpen={isModalOpen}
                                selectedRow={selectedRow}
                                isHover={false}
                            />
                            {isModalDeleteOpen && (
                                <Modal
                                    isOpen={isModalDeleteOpen}
                                    setIsOpen={setIsModalDeleteOpen}
                                    children={
                                        <DeleteModal
                                            surveyId={surveyId}
                                            questionId={selectedRow.id}
                                            optionId=""
                                            name={selectedRow.name} 
                                            requestType="question"
                                            closeDeleteModal={() => setIsModalDeleteOpen(false)}
                                        />
                                    }
                                />
                            )}

                            {isModalEditOpen && (
                                <Modal
                                    isOpen={isModalEditOpen}
                                    setIsOpen={setIsModalEditOpen}
                                    children={
                                        <EditModal
                                            surveyId={surveyId}
                                            requestType="question"
                                            closeEditModal={() => setIsModalEditOpen(false)}
                                            data={selectedRow} 
                                            formQuestionTypes={questionTypesData}
                                        />
                                    }
                                />
                            )}

                            {isModalAddOpen && (
                                <Modal
                                    isOpen={isModalAddOpen}
                                    setIsOpen={setIsModalAddOpen}
                                    children={
                                        <AddModal
                                            surveyId={surveyId}
                                            requestType="question"
                                            closeAddModal={() => setIsModalAddOpen(false)}
                                            name={name}
                                        />
                                    }
                                />
                            )}
                        </>
                    )} 

                    <Footer>
                        <TableColumnLeft>
                            Total Number of Questions: {totalNumber}
                        </TableColumnLeft>
                        <TableColumnRight>
                            {visibleRows.length !== 0 && (
                                <Pagination>
                                    <span>
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <PaginationButton
                                        onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                                        disabled={currentPage === 1}
                                    >
                                        Prev
                                    </PaginationButton>
                                    <PaginationButton
                                        onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </PaginationButton>
                                </Pagination>
                            )}
                        </TableColumnRight>
                    </Footer>
                </>
            </Body>
        </>
    );
};

export default QuestionOptionsTable;

const CardMainTable = styled.div`
    display: flex;
    flex-direction: column;
`;

const UserConsoleText = styled.h1`
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    color: ${setColor.accent4};
    position: relative;
    cursor: pointer;
`;

const StyledEditIcon = styled(EditIcon)`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const StyledViewAnsIcon = styled(ViewAnsIcon)`
    width: 25px;
    height: 25px;
    cursor: pointer;
`;

const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin-top: 12px !important;
    transform: rotate(90deg) !important;
`;

const CardTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem 1rem 1rem;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
    background: ${setColor.onHoverTextcolor2};
    border-radius: 5px;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Footer = styled.div`
    width: 100%;
    color: ${setColor.inputText};
    display: flex;
    margin-bottom: 1rem;
`;

const TableColumnRight = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }   
`;

const StyledDeleteIcon = styled(DeleteIcon)`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const Pagination = styled.div`
    justify-content: flex-end;
    font-size: 14px;
    color: ${setColor.inputText}; 
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const PaginationButton = styled.button`
    border-radius: 5px;
    background: ${setColor.iconColor3};
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: ${setColor.blackColor};
    // font-family: ${setFont.barlow}, sans-serif;
    font-size: 12px;

    &:disabled {
        background: ${setColor.darkPurple};
        cursor: not-allowed;
        color: ${setColor.blackDisbaled}; 
    }
`;

const TableColumnLeft = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }   
`;