import React from 'react';
import styled from 'styled-components';
import { setColor } from '../../../assets/styles/styles';
import Facebook from '../assets/socialShareIcons/UI_Btn_Web_Social_FB.png';
import SMS from '../assets/socialShareIcons/UI_Btn_Web_Social_SMS.png';
import Email from '../assets/socialShareIcons/UI_Btn_Web_Social_Email.png';
import CopyLink from '../assets/socialShareIcons/UI_Btn_Web_Social_Link.png';
// import Messenger from '../assets/socialShareIcons/UI_Btn_Web_Social_Messanger.png';
// import TikTok from '../assets/socialShareIcons/UI_Btn_Web_Social_TikTok.png';
// import Instagram from '../assets/socialShareIcons/UI_Btn_Web_Social_Insta.png';
// import Discord from '../assets/socialShareIcons/UI_Btn_Web_Social_Discord.png';
import X from '../assets/socialShareIcons/UI_Btn_Web_Social_X.png';
import Whatsapp from '../assets/socialShareIcons/UI_Btn_Web_Social_Whatsapp.png';
import LinkedIn from '../assets/socialShareIcons/UI_Btn_Web_Social_Linkedin.png';
import Typography from '../../../components/common/text/Typography';
import { isMobileDevice } from '../../../utils/generalUtils';

const SocialShareModal = () => {
    const isMobile = isMobileDevice();
    const url = window.location.href; // current page URL
    const title = document.title || 'Check this out!'; // Page title or a custom title

    // const handleWebShare = async () => {
    //     if (navigator.share) {
    //         try {
    //             await navigator.share({
    //                 title,
    //                 text: 'Check out this website!',
    //                 url,
    //             });
    //         } catch (error) {
    //             console.error('Error sharing', error);
    //         }
    //     } else {
    //         alert('Web Share API is not supported in this browser.');
    //     }
    // };

    // Copy link to clipboard
    const copyLink = () => {
        navigator.clipboard.writeText(url).then(
            () => alert('Link copied to clipboard!'),
            (err) => console.error('Failed to copy link: ', err)
        );
    };

    // Share via email
    const shareEmail = () => {
        window.location.href = `mailto:?subject=${encodeURIComponent(
            title
        )}&body=${encodeURIComponent(`Check out this link: ${url}`)}`;
    };

    // Share via SMS
    const shareSMS = () => {
        window.location.href = `sms:?body=${encodeURIComponent(`Check out this link: ${url}`)}`;
    };

    const handleFacebookShare = () => {
        const shareUrl = window.location.href; // Ensure this URL is accessible and correct
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;

        // Open the Facebook share dialog
        window.open(
            facebookShareUrl,
            '_blank', // Open in a new tab
            'width=800,height=600'
        );
    };

    // Share on Messenger
    // const shareMessenger = () => {
    //     window.open(
    //         `https://www.facebook.com/dialog/send?link=${encodeURIComponent(
    //             url
    //         )}&app_id=YOUR_FACEBOOK_APP_ID&redirect_uri=${encodeURIComponent(url)}`,
    //         '_blank'
    //     );
    // };

    // // Share on Discord (using a direct link)
    // const shareDiscord = () => {
    //     window.open(`https://discord.com/channels/@me?jump=${encodeURIComponent(url)}`, '_blank');
    // };

    // Share on LinkedIn
    const shareLinkedIn = () => {
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank');
    };

    // Share on X (Twitter)
    const shareX = () => {
        window.open(
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`,
            '_blank'
        );
    };

    // Share on WhatsApp
    const shareWhatsApp = () => {
        window.open(`https://wa.me/?text=${encodeURIComponent(`Check out this link: ${url}`)}`, '_blank');
    };

    // Share on TikTok (no direct link, use Web Share API or copy link)
    // const shareTikTok = () => {
    //     if (navigator.share) {
    //         handleWebShare();
    //     } else {
    //         copyLink();
    //     }
    // };

    // // Share on Instagram (no direct link, use Web Share API or copy link)
    // const shareInstagram = () => {
    //     if (navigator.share) {
    //         handleWebShare();
    //     } else {
    //         copyLink();
    //     }
    // };

    return (
        <Container>
            <SocialButtonsContainer>
                <IconContainer onClick={() => copyLink()}>
                    <SocialLogoBlock>
                        <SocialLogo src={CopyLink} alt="Copy Link" />
                    </SocialLogoBlock>
                    <Typography variant="pWhite" style={{ margin: '0' }}>
                        Copy Link
                    </Typography>
                </IconContainer>
                <IconContainer onClick={() => shareEmail()}>
                    <SocialLogoBlock>
                        <SocialLogo src={Email} alt="Email" />
                    </SocialLogoBlock>
                    <Typography variant="pWhite" style={{ margin: '0' }}>
                        Email
                    </Typography>
                </IconContainer>
                {isMobile && (
                    <IconContainer onClick={() => shareSMS()}>
                        <SocialLogoBlock>
                            <SocialLogo src={SMS} alt="SMS" />
                        </SocialLogoBlock>
                        <Typography variant="pWhite" style={{ margin: '0' }}>
                            SMS
                        </Typography>
                    </IconContainer>
                )}

                {/* <IconContainer onClick={() => shareDiscord()}>
                    <div>
                        <SocialLogo src={Discord} alt="Discord" />
                    </div>
                    <Typography variant="pWhite" style={{ margin: '0' }}>
                        Discord
                    </Typography>
                </IconContainer> */}
                <IconContainer onClick={() => handleFacebookShare()}>
                    <div>
                        <SocialLogo src={Facebook} alt="Facebook" />
                    </div>
                    <Typography variant="pWhite" style={{ margin: '0' }}>
                        Facebook
                    </Typography>
                </IconContainer>
                {/* <IconContainer>
                    <div>
                        <SocialLogo src={Messenger} alt="Messenger" />
                    </div>
                    <Typography variant="pWhite" style={{ margin: '0' }}>
                        Messenger
                    </Typography>
                </IconContainer> */}
                {/* <IconContainer onClick={() => shareTikTok()}>
                    <div>
                        <SocialLogo src={TikTok} alt="TikTok" />
                    </div>
                    <Typography variant="pWhite" style={{ margin: '0' }}>
                        TikTok
                    </Typography>
                </IconContainer> */}
                {/* <IconContainer onClick={() => shareInstagram()}>
                    <div>
                        <SocialLogo src={Instagram} alt="Instagram" />
                    </div>
                    <Typography variant="pWhite" style={{ margin: '0' }}>
                        Instagram
                    </Typography>
                </IconContainer> */}
                <IconContainer onClick={() => shareX()}>
                    <div>
                        <SocialLogo src={X} alt="X" />
                    </div>
                    <Typography variant="pWhite" style={{ margin: '0' }}>
                        X
                    </Typography>
                </IconContainer>
                <IconContainer onClick={() => shareWhatsApp()}>
                    <div>
                        <SocialLogo src={Whatsapp} alt="WhatsApp" />
                    </div>
                    <Typography variant="pWhite" style={{ margin: '0' }}>
                        WhatsApp
                    </Typography>
                </IconContainer>
                <IconContainer onClick={() => shareLinkedIn()}>
                    <div>
                        <SocialLogo src={LinkedIn} alt="LinkedIn" />
                    </div>
                    <Typography variant="pWhite" style={{ margin: '0' }}>
                        LinkedIn
                    </Typography>
                </IconContainer>
            </SocialButtonsContainer>
        </Container>
    );
};

export default SocialShareModal;

const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    @media (max-width: 960px) {
        margin-bottom: 15px;
    }
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const SocialButtonsContainer = styled.div`
    width: 80%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    justify-items: center;
    align-items: center;

    @media (max-width: 960px) {
        grid-template-columns: repeat(3, 1fr); // Adjust for smaller screens
        width: 100%;
    }
`;

const SocialLogo = styled.img`
    height: 70px;
    cursor: pointer;

    @media (min-width: 768px) and (max-width: 1024px) {
        height: 60px;
        margin: 1rem;
        margin: 1rem 0.5rem;
    }
`;

const SocialLogoBlock = styled.div`
    background: ${setColor.accent4}1a;
    border-radius: 20px;
    border: 2px solid rgba(190, 174, 255, 0.3);
`;
