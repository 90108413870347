import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { setColor, setFont } from '../../../../assets/styles/styles';
import { Input } from '../../../../components/common/input/Input';
import Button from '../../../../components/common/button/Button';
import { device } from '../../../../assets/styles/mediaBreakpoints';
import { isValidEmail } from '../../../../utils/generalUtils';
import { EMAIL_ERROR_MESSAGE } from '../../../../constants';
import { sendMailChimpEmail } from '../../../../api/MailchimpController';

const ButtonSection = ({ videoUrl, buttonText }) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showEmailForm, setShowEmailForm] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState(EMAIL_ERROR_MESSAGE);

    const buttonSectionRef = useRef(null);

    const updateEmail = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setEmail(value);
    };

    const submitEmail = async (e) => {
        setEmailError(false);

        e.preventDefault();
        if (!isValidEmail(email)) {
            return setEmailError(true);
        }

        const result = await sendMailChimpEmail(email);

        if (result === 200) {
            setSuccess(true);
        } else if (result === 400) {
            setEmailError(true);
            setEmailErrorMessage('This email address has already been subscribed.');
        } else {
            setEmailError(true);
            setEmailErrorMessage('There was a problem with subscribing, please try again.');
        }

        setTimeout(() => {
            setShowEmailForm(false);
            setSuccess(false);
            setEmail('');
            setEmailError(false);
            setEmailErrorMessage(EMAIL_ERROR_MESSAGE);
        }, 5000);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            () => {
                setShowEmailForm(false);
                setSuccess(false);
                setEmail('');
                setEmailError(false);
                setEmailErrorMessage(EMAIL_ERROR_MESSAGE);
            },
            {
                root: null, // Use the viewport as the container
                rootMargin: '0px',
                threshold: 0.1, // Trigger when 10% of the component is visible
            }
        );

        if (buttonSectionRef.current) {
            observer.observe(buttonSectionRef.current);
        }

        return () => {
            if (buttonSectionRef.current) {
                observer.unobserve(buttonSectionRef.current);
            }
        };
    }, []);

    return (
        <VideoContainer ref={buttonSectionRef}>
            <video
                style={{
                    zIndex: '-500',
                    height: '100vh',
                    width: '100vw',
                    objectFit: 'cover',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                }}
                controls={false}
                muted
                autoPlay
                loop
            >
                <source src={videoUrl} type="video/mp4" />
            </video>
            <HeroBackgroundOverlay />
            <HeroContent>
                {showEmailForm && (
                    <EmailInputContainer>
                        <CustomPurpleText style={{ fontSize: '14px' }} outline>
                            Coming soon! Get early access:
                        </CustomPurpleText>
                        <Input
                            variant="LoginInput"
                            type="email"
                            placeholder="What's your email"
                            value={email}
                            onChangeHandler={(e) => updateEmail(e)}
                            error={emailError}
                            success={success}
                            errorMessage={emailError ? emailErrorMessage : ''}
                        />
                    </EmailInputContainer>
                )}
                <Button
                    variant="PinkButton"
                    label={success ? 'Email Submitted' : buttonText}
                    onClick={showEmailForm ? submitEmail : () => setShowEmailForm(true)}
                    style={{ ...(success && { background: '#166443' }) }}
                    disabled={success}
                />
            </HeroContent>
        </VideoContainer>
    );
};

ButtonSection.propTypes = {
    videoUrl: PropTypes.string,
    buttonText: PropTypes.string,
};
export default ButtonSection;

const HeroBackgroundOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -200;
    background-color: #00000080;
    mix-blend-mode: multiply;
    pointer-events: none;
`;

const VideoContainer = styled.div`
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 65%, ${setColor.darkPurpleBackground} 100%);
    // @media ${device.desktopWide} {
    //     height: 55vh;
    // }

    // @media (max-width: 960px) {
    //     height: 65vh;
    // }
`;

const HeroContent = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 95%;
    }
`;

const CustomPurpleText = styled.p`
    color: ${setColor.accent4};
    font-family: ${setFont.barlow};
    font-size: 12px;
    font-weight: bolder;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;

    ${(props) => {
        if (props.outline)
            return `-webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: rgba(0, 0, 0, 0.5);`;
    }}
`;

const EmailInputContainer = styled.div`
    width: 500px;
    margin-bottom: 15px;

    @media (max-width: 960px) {
        width: 90%;
    }
`;
