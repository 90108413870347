import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '../../../components/common/text/Typography';

const Title = ({ title, description, button }) => (
    <HeaderContainer>
        <Text>
            <Typography variant="ConsoleTitle">{title}</Typography>
            <DescriptionText variant="ConsoleDescription">{description}</DescriptionText>
        </Text>
        {button && <Button>{button}</Button>}
    </HeaderContainer>
);

Title.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button: PropTypes.node,
};

export default Title;

const DescriptionText = styled(Typography)`
    @media (max-width: 768px) {
        text-align: center;
    }
`;

const HeaderContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0;

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center !important;
        margin: 1rem 0 1.5rem 0;
    }
`;

const Text = styled.div`
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
    }
`;

const Button = styled.div`
    @media (max-width: 768px) {
        margin-top: 2rem;
    }
`;
