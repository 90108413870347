import { API_GET, API_DELETE, API_PUT, API_POST } from '../../../../../config/axiosConfig';

export async function getAllRoles() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/roles`;
        const respAxios = await API_GET(url);
        if(respAxios?.status === 200){
            return respAxios?.data?.roles;
        }
        return [];
    } catch (err) {
        console.log('Error fetching data:', err);
        return [];
    }
}

export async function getAllPermissions() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/permissions`;
        const respAxios = await API_GET(url);
        if(respAxios?.status === 200){
            return respAxios?.data?.permissions;
        }
        return [];
    } catch (err) {
        console.log('Error fetching data:', err);
        return [];
    }
}


export async function getAllPermissionsByRoleId(roleId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/permissions/${roleId}`;
        const respAxios = await API_GET(url);
        if(respAxios?.status === 200){
            return respAxios?.data?.permissions;
        }
        return [];
    } catch (err) {
        console.log('Error fetching data:', err);
        return [];
    }
}

export async function deleteRole(id) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/roles/${id}`;
        const respAxios = await API_DELETE(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}


export async function addRole(roleName) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/roles`;
        
        const respAxios = await API_POST(url, {
            roleName,
        });
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function updateRole(roleId, name) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/roles/${roleId}`;
        return await API_PUT(url,
            {
                roleName: name,
            }
        );
    } catch (err) {
        return err;
    }
}

export async function addPermissionintoRole(roleId, permissionIds) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/permissions/${roleId}`;
        return await API_POST(url,
            {
                permissionIds,
            }
        );
    } catch (err) {
        return err;
    }
}

export async function RemovePermissionFromRole(roleId, permissionId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/permissions/${roleId}/${permissionId}`;
        const respAxios = await API_DELETE(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}