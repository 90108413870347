import styled from 'styled-components';
import Button from '../button/Button';

const FileUploader = ({ handleFileChange, handleUpload, btnDisabled }) => {
  return (
    <div>
      {/* Custom file input */}
      <FileInputLabel htmlFor="fileInput">Choose File</FileInputLabel>
      
      <HiddenFileInput
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
      />

      {/* Upload Button */}
      <Button
        variant={'DefaultButton'}
        disabled={btnDisabled}
        label={'Upload and Process Image'}
        onClick={handleUpload}
        margin={'20px auto'}
    />
    </div>
  );
};

export default FileUploader;

const FileInputLabel = styled.label`
    display: inline-block;
    padding: 10px 20px;
    background: #252333 !important;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 15px;
    &:hover {
        background-color: #0056b3;
    }
`;

const HiddenFileInput = styled.input`
  display: none;
`;
