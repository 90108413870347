import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateUsername } from '../../redux/actions/UserActions';
import female2 from '../../assets/images/Avatars/female2.png';
import female3 from '../../assets/images/Avatars/female3.png';
import female4 from '../../assets/images/Avatars/female4.png';
import male1 from '../../assets/images/Avatars/male1.png';
import male2 from '../../assets/images/Avatars/male2.png';
import male3 from '../../assets/images/Avatars/male3.png';
import male4 from '../../assets/images/Avatars/male4.png';
import female1 from '../../assets/images/Avatars/female1.png';
import Typography from '../../components/common/text/Typography';
import Iconify from '../../components/common/icon/Iconify';
import CrystalButton from '../../assets/images/buttonIcons/Crystal-button-BG.png';
import UserNameIcon from '../../assets/images/icons/username.svg';
import { setColor } from '../../assets/styles/styles';
import { Input } from '../../components/common/input/Input';
import Scrollbar from '../../components/Scrollbar';
import { fetchUserId, fetchUserToken } from '../../redux/slices/users';
import avatarPresetMetadata from './helpers/avatarPresetMetadata.json';
import { validateUsername } from '../../utils/generalUtils';
import { setToSessionStorage } from '../../hooks/useSessionStorage';
import { usernameExistsChecker, sendEventsLog } from '../../api/UserController';
import AvatarBackground from '../../assets/images/backgrounds/UI_BG_Avatar_Selection.png';
import { fetchVanityLinkAvatarPresets } from '../../api/VanityLinkController';

const VanityAvatarPage = ({
    username,
    setUsername,
    setCurrentScreen,
    noUsername,
    vanityLink,
    handleRegisterSubmit,
}) => {
    const dispatch = useDispatch();
    const [selectedAvatar, setSelectedAvatar] = useState(null); // Default selection
    const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
    const [invalidUsernameError, setInvalidUsernameError] = useState('');
    const token = useSelector(fetchUserToken);
    const userId = useSelector(fetchUserId);
    const [avatars, setAvatars] = useState([]);
    const [fetchDefaultAvatarsTrue, setFetchDefaultAvatarsTrue] = useState(false);

    const defaultPresets = [
        { name: 'female2', image_url: female2, index: 0 },
        { name: 'male4', image_url: male4, index: 1 },
        { name: 'female4', image_url: female4, index: 2 },
        { name: 'male1', image_url: male1, index: 3 },
        { name: 'female1', image_url: female1, index: 4 },
        { name: 'male3', image_url: male3, index: 5 },
        { name: 'female3', image_url: female3, index: 6 },
        { name: 'male2', image_url: male2, index: 7 },
    ];

    function getAvatarJSON(selectedAvatar) {
        // Find the corresponding avatar object
        const selectedAvatarObj = avatars.find((avatar) => avatar.index === selectedAvatar.index);
        // If the selectedAvatar object is found
        if (selectedAvatarObj) {
            // Find the corresponding JSON object in the avatarPreserMetadata.json array
            const json = avatarPresetMetadata.find((item) => item.index === selectedAvatarObj.index);
            // If JSON object is found, return it
            if (json) {
                return json.json;
            }
            console.error('JSON data not found for selected avatar');
            return null;
        }
        console.error('Avatar object not found');
        return null;
    }

    const handleAvatarUpdate = async () => {
        if (noUsername) {
            setCurrentScreen('vanityRedirect');
        }
        if (invalidUsernameError === '' && username.length > 2 && !noUsername) {
            setUsername(username);
            dispatch(UpdateUsername(username));
            await handleRegisterSubmit();
            setCurrentScreen('vanityRedirect');
        } else {
            setInvalidUsernameError('Please enter a valid username before proceeding.');
        }
    };

    const fetchAvatarPresets = async (vanityLink) => {
        const response = await fetchVanityLinkAvatarPresets(vanityLink);

        if (response?.status === 200) {
            setAvatars(response.avatarPresets);
            setFetchDefaultAvatarsTrue(false);
            setToSessionStorage('selectedAvatar', response.avatarPresets[0].name);
            setSelectedAvatar(response.avatarPresets[0]);
        } else {
            setFetchDefaultAvatarsTrue(true);
            setDefaultAvatars();
        }
    };

    const setDefaultAvatars = async () => {
        setSelectedAvatar(defaultPresets[0]);
        setToSessionStorage('selectedAvatar', defaultPresets[0].name);
        setAvatars(defaultPresets);
    };

    useEffect(() => {
        if (vanityLink) {
            fetchAvatarPresets(vanityLink);
        } else {
            setDefaultAvatars();
        }
    }, []);

    useEffect(() => {
        if (username.trim() !== '') {
            usernameExistsChecker(username).then((res) => {
                if (res) {
                    setInvalidUsernameError('Username already exists, please try a unique username.');
                } else if (!validateUsername(username) && username.length < 2) {
                    setInvalidUsernameError(
                        'Username must start with a letter, can only contain letters, numbers, underscores, or hyphens, and be between 3 and 20 characters long.'
                    );
                } else {
                    setInvalidUsernameError('');
                }
            });
        }
    }, [username]);

    const handleAvatarClick = (avatar) => {
        setSelectedAvatar(avatar);
        setToSessionStorage('selectedAvatar', avatar.name);
    };

    const handleBack = () => {
        if (noUsername) {
            window.location.reload();
        } else {
            setCurrentScreen('vanityEmailPage');
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Container orientation={orientation}>
            <Iconify
                style={{
                    position: 'absolute',
                    top: '20px',
                    left: '20px',
                    height: '50px',
                    zIndex: 1000,
                    cursor: 'pointer',
                }}
                Color={setColor.accent4}
                onClick={handleBack}
                icon="material-symbols:arrow-back-ios"
            />
            <AvatarSelector>
                <Scrollbar sx={{ margin: '20px' }}>
                    <Typography style={{ fontWeight: 500, fontSize: '22px', marginTop: '0rem' }} variant={'pWhite'}>
                        {noUsername ? 'Lastly, choose your Avatar' : 'Lastly, choose your username and avatar!'}
                    </Typography>
                    {!noUsername && (
                        <Input
                            variant="LoginInput"
                            type="name"
                            value={username}
                            maxLength={19}
                            borderRadius="0px"
                            containerStyle={{
                                height: '60px',
                                width: '100%',
                                paddingLeft: '10px',
                            }}
                            placeholder="Enter your username"
                            onChangeHandler={(e) => setUsername(e.target.value)}
                            inputIconColor={
                                invalidUsernameError === '' && username.length > 2 ? setColor.inputGreen : setColor.grey
                            }
                            customIcon={UserNameIcon}
                            checkMarkIcon={'carbon:checkmark-filled'}
                        />
                    )}
                    {invalidUsernameError && (
                        <p style={{ marginBottom: '25px', color: setColor.errorColor }}>{invalidUsernameError}</p>
                    )}
                    <AvatarGrid>
                        {avatars.map((avatar) => (
                            <AvatarThumbnailContainer
                                isSelected={avatar.index === selectedAvatar.index}
                                key={avatar.index}
                                onClick={() => handleAvatarClick(avatar)}
                            >
                                <AvatarThumbnail
                                    isSelected={avatar.index === selectedAvatar.index}
                                    style={{ backgroundImage: `url(${avatar.image_url})` }}
                                />
                            </AvatarThumbnailContainer>
                        ))}
                    </AvatarGrid>
                </Scrollbar>
            </AvatarSelector>
            <AvatarPreview>
                <Typography
                    style={{
                        fontWeight: 500,
                        fontSize: '20px',
                        color: setColor.lightPurple,
                        wordBreak: 'break-all',
                        margin: '0',
                        padding: '0',
                        minHeight: '32px',
                    }}
                    variant={'pWhite'}
                >
                    {username}
                </Typography>
                {selectedAvatar ? (
                    <SelectedAvatar orientation={orientation} src={selectedAvatar.image_url} alt="Selected Avatar" />
                ) : (
                    <></>
                )}
                <JumpInButton onClick={handleAvatarUpdate}>Jump In</JumpInButton>
            </AvatarPreview>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    backdrop-filter: blur(100px);
    background: ${setColor.containerGradient};

    ${(props) => {
        if (props.orientation && props.orientation === 'portrait') {
            return `
        transform: rotate(-90deg);
        height:100vw;
        width: 100vh;
      `;
        }
        return `
        transform: none;
        height: 100%;
        width: 100%;
      `;
    }}
`;

const SelectedAvatar = styled.img`
    height: 100vh;
    flex: 1;

    ${(props) => {
        if (props.orientation) {
            return `height:70%;`;
        }
    }}

    @media (max-width: 1020px) {
        flex: 0;
        margin: 5px 0;
    }
`;

const AvatarSelector = styled.div`
    flex: 3;
    overflow-y: auto;
    padding: 20px 20px 0 20px;
    width: 80%;
`;

const AvatarGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    margin-top: 2.3rem;
    padding-bottom: 2.5rem;

    @media (max-width: 1020px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }
`;

const AvatarThumbnailContainer = styled.div`
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    backdrop-filter: blur(100px);
    background: ${(props) => (props.isSelected ? setColor.backgroundAvatar : setColor.purpleDark)};
    border-radius: 5px;
`;

const AvatarThumbnail = styled.div`
    background-size: 240% auto;
    background-position: center top;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: ${(props) => (props.isSelected ? `4px solid ${setColor.iconColor3}}` : 'none')};
    transition: transform 0.2s ease-in-out;
`;

const AvatarPreview = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 45px 20px 0 20px;
    width: 30vw;
    height: 100vh;
    background-image: url(${AvatarBackground});
    background-size: cover;
    border-left: 3px solid ${setColor.backgroundAvatar};

    @media (max-width: 1020px) {
        height: 100%;
        padding: 15px;
        margin: 0;
        justify-content: center;
    }

    @media (max-width: 425px) {
        width: 30vh;
    }
`;

const JumpInButton = styled.button`
    margin-top: 35px;
    width: 100%;
    border: none;
    height: 65px;
    flex-shrink: 0;
    background: url(${CrystalButton});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    color: #1b1b23;

    text-align: center;
    leading-trim: both;

    text-edge: cap;
    font-family: Barlow;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    letter-spacing: 3.6px;
    text-transform: uppercase;

    @media (max-width: 1020px) {
        font-size: 16px;
        letter-spacing: 1.8px;
        margin-top: 0;
        height: 38px;
    }
`;

export default VanityAvatarPage;
