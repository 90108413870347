import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IntraverseLogo from '../assets/logos/IntraverseLogo.svg';
import { setColor, setFont } from '../../../assets/styles/styles';
import { Input } from '../../../components/common/input/Input';
import gradiantArrow from '../assets/gradientArrow.png';
import Button from '../../../components/common/button/Button';
import { isValidEmail, isMobileDevice } from '../../../utils/generalUtils';
import { EMAIL_ERROR_MESSAGE } from '../../../constants';
import Typography from '../../../components/common/text/Typography';
import IntraverseLogoWithIcon from '../assets/logos/Intraverse_Logo_With_Icon.svg';
import TZIcon from '../assets/logos/TZIcon.svg';
import { sendMailChimpEmail } from '../../../api/MailchimpController';

const HeroContainer = ({ videoUrl, buttonText, description, arrowText, pro, title, fadeColor }) => {
    const isMobile = isMobileDevice();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showEmailForm, setShowEmailForm] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState(EMAIL_ERROR_MESSAGE);
    const heroRef = useRef(null);

    const updateEmail = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setEmail(value);
    };

    const submitEmail = async (e) => {
        setEmailError(false);

        e.preventDefault();
        if (!isValidEmail(email)) {
            return setEmailError(true);
        }

        const result = await sendMailChimpEmail(email);

        if (result === 200) {
            setSuccess(true);
        } else if (result === 400) {
            setEmailError(true);
            setEmailErrorMessage('This email address has already been subscribed.');
        } else {
            setEmailError(true);
            setEmailErrorMessage('There was a problem with subscribing, please try again.');
        }

        setTimeout(() => {
            setShowEmailForm(false);
            setSuccess(false);
            setEmail('');
            setEmailError(false);
            setEmailErrorMessage(EMAIL_ERROR_MESSAGE);
        }, 5000);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            () => {
                setShowEmailForm(false);
                setSuccess(false);
                setEmailError(false);
                setEmailErrorMessage(EMAIL_ERROR_MESSAGE);
                setEmail('');
            },
            {
                root: null, // Use the viewport as the container
                rootMargin: '0px',
                threshold: 0.1, // Trigger when 10% of the component is visible
            }
        );

        if (heroRef.current) {
            observer.observe(heroRef.current);
        }

        return () => {
            if (heroRef.current) {
                observer.unobserve(heroRef.current);
            }
        };
    }, []);

    return (
        <VideoContainer ref={heroRef} fadeColor={fadeColor}>
            {pro && <IntraverseLogoWithIconImg src={isMobile ? TZIcon : IntraverseLogoWithIcon} />}
            <video
                style={{
                    zIndex: '-500',
                    height: '100vh',
                    width: '100vw',
                    objectFit: 'cover',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                }}
                controls={false}
                muted
                autoPlay
                loop
            >
                <source src={videoUrl} type="video/mp4" />
            </video>
            <HeroBackgroundOverlay pro={pro} />
            <HeroContent>
                {pro ? (
                    <Typography variant="LandingPageTitle" style={{ color: 'rgba(190, 174, 255, 0.50)', margin: '0' }}>
                        {title}
                    </Typography>
                ) : (
                    <HeroLogo src={IntraverseLogo} alt="Hero Logo Intraverse" />
                )}
                <HeroSubtitle>{description}</HeroSubtitle>
                {showEmailForm && (
                    <EmailInputContainer>
                        <CustomPurpleText outline>Coming soon! Get early access:</CustomPurpleText>
                        <Input
                            variant="LoginInput"
                            type="email"
                            placeholder="What's your email"
                            value={email}
                            onChangeHandler={(e) => updateEmail(e)}
                            error={emailError}
                            success={success}
                            errorMessage={emailError ? emailErrorMessage : ''}
                        />
                    </EmailInputContainer>
                )}
                <Button
                    variant="PinkButton"
                    label={success ? 'Email Submitted' : buttonText}
                    onClick={
                        showEmailForm
                            ? submitEmail
                            : (e) => {
                                  e.preventDefault();
                                  setShowEmailForm(true);
                              }
                    }
                    style={{ ...(success && { background: '#166443' }) }}
                    disabled={success}
                />
                <ScrollText>
                    <CustomPurpleText style={{ fontSize: '14px' }}>{arrowText}</CustomPurpleText>
                    <ScrollDownArrowImg src={gradiantArrow} alt={'Scroll down arrow'} />
                </ScrollText>
            </HeroContent>
        </VideoContainer>
    );
};

HeroContainer.propTypes = {
    videoUrl: PropTypes.string,
    buttonText: PropTypes.string,
    description: PropTypes.string,
    arrowText: PropTypes.string,
    pro: PropTypes.bool,
    title: PropTypes.string,
    fadeColor: PropTypes.string,
};
export default HeroContainer;

const IntraverseLogoWithIconImg = styled.img`
    height: 30px;
    width: auto;
    position: fixed;
    top: 25px;
    left: 25px;
    z-index: 9999;

    @media (max-width: 960px) {
        height: 25px;
    }
`;

const EmailInputContainer = styled.div`
    width: 500px;
    margin-bottom: 15px;

    @media (max-width: 960px) {
        width: 90%;
    }
`;

const HeroBackgroundOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -200;
    background-color: ${setColor.heroBackground};
    mix-blend-mode: multiply;
    pointer-events: none;
    opacity: ${(props) => (props.pro ? '' : '0.55')};
`;

const VideoContainer = styled.div`
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, ${(props) => props.fadeColor} 95%);
`;

const HeroContent = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 95%;
    }
`;
const HeroLogo = styled.img`
    height: 110px;

    @media (max-width: 960px) {
        height: 50px;
    }
`;

const HeroSubtitle = styled.h3`
    color: ${setColor.inputText};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 1.75rem;
    font-weight: 600;
    margin: 2rem;

    @media (max-width: 768px) {
        font-size: 1rem;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 1.3rem;
    }
`;
const ScrollDownArrowImg = styled.img`
    height: 75px;
    margin-top: 5px;
`;

const CustomPurpleText = styled.p`
    color: ${setColor.accent4};
    font-family: ${setFont.barlow};
    font-size: 14px;
    font-weight: bolder;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;

    ${(props) => {
        if (props.outline)
            return `-webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: rgba(0, 0, 0, 0.5);`;
    }}

    @media (max-width: 960px) {
        font-size: 12px;
    }
`;

const ScrollText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 0;
`;
