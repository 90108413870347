import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import purpleX from '../../assets/images/buttonIcons/purpleX.png';
import { sendVagonMessage } from '../vagon/helpers/utils';
import * as constants from '../../constants';
import Modal from '../../pages/console/components/Modal';
import Typography from '../../components/common/text/Typography';
import Button from '../../components/common/button/Button';
// import { LoginWithLionGaming, GetUrlLionGaming } from './apiController';
// import Banner from '../../components/layout/ui/Banner';

// const casinoGames = [
//     '5dee2eb29db4d9001cd9ee9c',
//     '5caf2fefe9688a004883d48d',
//     '5c669b77362176000f18b9e7',
//     '60faa3b0589d2900175cb98e',
//     'pragm__vs25chilli',
//     '5bb325cf6b226f000e000006',
//     'pragm__vs25mustang',
//     'wackyPandaDesktop',
//     'pragm__vs25wolfgold',
//     'pragm__vs5joker',
// ];

const LionGamingCasino = (props) => {
    const email = useSelector((state) => state?.user?.email);
    const [modalOpen, setModalOpen] = React.useState(false);
    // const [errorBanner, setErrorBanner] = React.useState('');
    const [url, setUrl] = React.useState(null);
    const modalMessage = useRef();

    const fetchUrl = async () => {
        try {
            const params = props.data.split('&').reduce((acc, param) => {
                const [key, value] = param.split('=');
                acc[key] = value;
                return acc;
            }, {});

            const liongame = params.liongame;
            if(liongame === '0') {            
                setUrl(constants.STATIC_GAMES[0]);
            } else if(liongame === '1') {
                setUrl(constants.STATIC_GAMES[1]);
            } else {
                setUrl(constants.STATIC_GAMES[2]);
            }
            // liongame will give index, match that index with casinoGames array
            // and get the game code
            // const gameId = casinoGames[liongame];
            // if (!gameId) {
            //     setErrorBanner('Game not found. Please try again later.');
            // }
            // const responseLogin = await LoginWithLionGaming(email);
            // if (responseLogin.status === 200 && responseLogin.data.accessToken && responseLogin.data.success) {
            //     const getUrl = await GetUrlLionGaming(responseLogin, gameId);
            //     if (getUrl.status === 200 && getUrl.data.success) {
            //         setUrl(getUrl.data.url);
            //     }
            // } else {
            //     setErrorBanner('An error occurred. Please try again later.');
            // }
        } catch (error) {
            console.error('Error fetching url:', error);
        }
    };

    useEffect(() => {
        fetchUrl();
    }, [props]);

    const updateModal = () => {
        setModalOpen((prev) => !prev);
    };

    const sendCloseEventLog = () => {
        // console.log('Web: sendCloseEventLog');
        // console.log('clientSessionId.current', clientSessionId.current);
        // const data = {
        //     timestamp: new Date().toISOString(),
        //     displayName: displayName.current,
        //     slot: slotRef.current,
        //     modalType: LOG_EVENTS__DATA_MODAL_TYPE,
        // };
        // sendEventsLog(
        //     bearerToken.current,
        //     'lion-gaming-casino',
        //     '',
        //     LOG_EVENTS_LISTENER_MODAL_CLOSE,
        //     'info',
        //     data,
        //     clientSessionId.current,
        //     envUnity.current
        // );
    };

    useEffect(() => {
        async function handleMessage(event) {
            if (event.data.messageType === 'closeGame') {
                modalMessage.current = event.data?.messageData?.message || 'An error occurred. Please try again later.';
                updateModal();
            }
        }

        window.addEventListener('message', handleMessage);

        // Cleanup function
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div>
            {/* <Banner
                width={'100%'}
                zIndex="1000"
                message={`${errorBanner}`}
                type={'ERROR'}
                openBanner={!!errorBanner}
                setOpenBanner={setErrorBanner}
                topDisplay
                showCloseButton
                gameScreen
                timer={8000}
                top={'0px'}
                left={'0'}
            /> */}
            <Modal
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                children={
                    <>
                        <Typography variant="pWhite" margin={'30px 0'}>
                            {modalMessage.current}
                        </Typography>
                        <Button
                            margin={'15px 0px 0px 0px'}
                            label="Okay"
                            height={'30px'}
                            variant="OutlinedButton"
                            onClick={() => {
                                // sendCloseEventLog();
                                sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                                props.setLionGamingSlot(null);
                                modalMessage.current = false;
                            }}
                            buttonWidth="100%"
                            fontSize="20px"
                        />
                    </>
                }
                closeButton={false}
            />
            {url && (
                <>
                    <iframe
                        title="Lion Gaming Iframe Content"
                        id="splash"
                        scrolling="no"
                        allow="autoplay"
                        style={{
                            position: 'absolute',
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                            padding: 0,
                            border: 0,
                            zIndex: 9998,
                            top: 0,
                            left: 0,
                        }}
                        src={url}
                    />

                    <CloseButton
                        type="button"
                        onClick={() => {
                            sendCloseEventLog();
                            sendVagonMessage(constants.UNITY_CLOSE_WEB_INTERFACE);
                            props.setLionGamingSlot(null);
                        }}
                    >
                        <StyledImage src={purpleX} alt="Close" />
                    </CloseButton>
                </>
            )}
        </div>
    );
};

export default LionGamingCasino;

const StyledImage = styled.img`
    @media (max-width: 960px) {
        width: 30px;
        height: auto;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    z-index: 9999;
    top: 45px;
    right: 25px;
    border: none;
    background: transparent;
    cursor: pointer;

    @media (max-width: 960px) {
        top: 40px;
        right: 15px;
    }
`;