import axios from 'axios';
import { store } from '../redux/Store';
import { DeleteUser, RefreshAccessToken } from '../redux/actions/UserActions';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_CORE_API_URI,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(request => {
    const state = store.getState();
    const accessToken = state?.user?.token;

    // Check if Authorization header is already set in the request config
    if (!request.headers.Authorization && accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    // Ensure the other headers are still set if not provided
    request.headers.Accept = request.headers.Accept || 'application/json';
    request.headers['Content-Type'] = request.headers['Content-Type'] || 'application/json';

    return request;
  }, error => {
    return Promise.reject(error);
  });

axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const state = store.getState();
                const refreshToken = state.user?.refreshToken;
                if (!refreshToken) {
                    console.error('No refresh token found. Redirecting to login.');
                    handleSessionExpiry();
                    return Promise.reject(error);
                }

                const url = `${process.env.REACT_APP_CORE_API_URI}/web/users/refresh-auth-token`;
                const response = await axios.post(url, {
                    refreshToken,
                });
                const { token, expiration, refreshToken: newRefreshToken } = response.data;
                store.dispatch(RefreshAccessToken(token, expiration, newRefreshToken));
                axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                handleSessionExpiry();
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

function handleSessionExpiry() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    store.dispatch(DeleteUser());

    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    window.location.href = '/login';
}

export const API_GET = async (url, config = {}) => {
    const response = await axiosInstance.get(url, config);
    return response;
};

export const API_POST = async (url, data = {}, config = {}) => {
    const response = await axiosInstance.post(url, data, config);
    return response;
};

export const API_PUT = async (url, data = {}, config = {}) => {
    const response = await axiosInstance.put(url, data, config);
    return response;
};

export const API_DELETE = async (url, config = {}) => {
    const response = await axiosInstance.delete(url, config);
    return response;
};

export default axiosInstance;
