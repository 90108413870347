import { API_POST, API_GET } from '../config/axiosConfig';
import { checkPermission } from '../utils/generalUtils';

export async function getPermissionsAndCheckAccess(role, permissionName) {
    try {
        const respPermissions = await API_GET(
            `${process.env.REACT_APP_CORE_API_URI}/web/permissions/role-name/${role}`
        );
        const permissions = [];
        for (let i = 0; i < respPermissions.data.permissions.length; i += 1) {
            permissions.push(respPermissions.data.permissions[i].name);
        }
        const resp = await checkPermission([permissionName], permissions);
        return resp;
    } catch (err) {
        return err;
    }
}
