import { API_GET, API_DELETE, API_POST, API_PUT } from '../../../../config/axiosConfig';

export async function getAllRoomsByUserId(userId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/users/${userId}/rooms`;
        const respAxios = await API_GET(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function removeMeFromRoom(roomId, userId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/users/${roomId}/${userId}`;
        const respAxios = await API_DELETE(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getRoomUsers(roomId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/users/${roomId}`;
        const respAxios = await API_GET(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function addUserToRoom(roomId, userId, roleId) {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms/users/${roomId}`;
        const respAxios = await API_PUT(url,
            {
                roomUserId: userId,
                roleId,
            }
        );
        return respAxios;
    } catch (err) {
        return err;
    }
}


export async function createRoom(name, code, type, freeFormJson, expiry, 
    subtitle, description, selectedUserId){
    try {        
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/rooms`;
        const respAxios = await API_POST(url,
            {
                name,
                code,
                roomType: type,
                json: freeFormJson,
                expiry,
                subtitle,
                description,
                userIdSelection: selectedUserId
            }
        );
        return respAxios;
    } catch (err) {
        return err;
    }
}

export async function getAllUsers() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/users`;
        const respAxios = await API_GET(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}


export async function getRoomTags() {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/rooms/tags`);

        return response.data.tags;
    } catch (err) {
        console.error('Error fetching roomTags:', err);
        return null;
    }
}

export async function getUserGroups() {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/groups`);

        return response.data.groups;
    } catch (err) {
        console.error('Error fetching userGroups:', err);
        return null;
    }
}

export async function getAllRoles() {
    try {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/roles`;
        const respAxios = await API_GET(url);
        return respAxios;
    } catch (err) {
        return err;
    }
}