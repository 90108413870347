import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchUserToken } from '../../../../redux/slices/users';
import { API_GET, API_POST } from '../../../../config/axiosConfig';
import PaymentMethod from './components/PaymentMethod';
import AddCartPopUp from './components/AddCartPopUp';
import StripeContainer from './components/StripeContainer';
import DisconnectTab from './components/DisconnectTab';
import Button from '../../../../components/common/button/Button';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import Body from '../../layout/Body';

const Wallet = () => {
    const token = useSelector(fetchUserToken);
    const email = useSelector((state) => state?.user?.email);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [toggleAddCart, setToggleAddCart] = useState(false);
    const [loading, setLoading] = useState(true);
    const [disconnectTabOn, setDisconnectTabOn] = useState(false);
    const [displayStripe, setDisplayStripe] = useState(false);
    const [clientSecret, setClientSecret] = useState(false);

    const fetchClientSecret = async () => {
        try {
            const response = await API_POST(`${process.env.REACT_APP_CORE_API_URI}/web/stripe/generate_intent`, null);
            setClientSecret(response.data.client_secret);
            setToggleAddCart(!toggleAddCart);
            setDisplayStripe(true);
        } catch (error) {
            console.log(error);
        }
    };

    const setupStripeAccount = async () => {
        try {
            const response = await API_POST(`${process.env.REACT_APP_CORE_API_URI}/web/stripe/customers`, { email });
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const fetchCustomerPaymentMethods = async () => {
        try {
            const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/stripe/payment_methods`);
            if (response.status === 200) {
                const { paymentMethods } = response.data;
                setPaymentMethods(paymentMethods);
            }
        } catch (error) {
            if (error.response?.status === 404 || error.response?.status === 400) {
                await setupStripeAccount();
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchCustomerPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Title
                title={'My Wallet'}
                description={'Manage and review your cards, payments, and billing information.'}
                button={
                    paymentMethods.length > 0 && (
                        <Button
                            buttonWidth="100%"
                            variant={'OutlinedButton'}
                            label="REMOVE PAYMENT METHOD"
                            onClick={() => {
                                setDisconnectTabOn(true);
                            }}
                        />
                    )
                }
            />
            <Body isLoading={loading}>
                {paymentMethods.length > 0
                    ? paymentMethods.map((paymentMethod, i) => (
                          <PaymentMethod key={`${i}`} paymentMethod={paymentMethod} />
                      ))
                    : !displayStripe && (
                          <AddCartPopUp toggleAddCart={toggleAddCart} fetchClientSecret={fetchClientSecret} />
                      )}
                {displayStripe && <StripeContainer clientSecret={clientSecret} />}
            </Body>
            <Modal
                isOpen={disconnectTabOn}
                setIsOpen={setDisconnectTabOn}
                children={<DisconnectTab setDisconnectTabOn={setDisconnectTabOn} />}
            />
        </>
    );
};

export default Wallet;
