import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import styled from '@emotion/styled';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import 'react-datetime/css/react-datetime.css';
import { setColor } from '../../../../../assets/styles/styles';
import Typography from '../../../../../components/common/text/Typography';
import Banner from '../../../../../components/layout/ui/Banner';
import { default as Button } from '../../../../../components/common/button/Button';
import { createRoomNew, getUserGroups, getRoomTags, getRoomPrefabs } from '../api/roomController';
import { CACHE_QUERY_OPTIONS } from '../../../../../constants';
import Dropdown from '../../../../../components/common/inputDropdown/Dropdown';
import UserSearchDropdown from '../../../components/UserSearchDropdown';
import ToggleSwitch from '../../../../../components/common/switchToggle/ToggleSwitch';
import LoadingIcon from '../../../../../components/common/loading/LoadingIcon';
import { createVanityLink, createVanityLinkData } from '../../../../../api/VanityLinkController';

const AddRoomModal = () => {
    const navigate = useNavigate();
    const timezone = 'America/Los_Angeles';
    const minDate = moment().startOf('day');
    const [successMessageBanner, setSuccessMessageBanner] = useState('');
    const [bannerType, setBannerType] = useState('SUCCESS');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [vanityLink, setVanityLInk] = useState(false);
    const [loading, setLoading] = useState(false);
    const [roomState, setRoomState] = useState({
        name: '',
        code: '',
        description: '',
        roomType: '',
        json: {},
        expiry: null,
        ownerId: '',
        tags: [],
        groupId: '',
        isVisibleInNavMenu: false,
    });

    const [vanityState, setVanityState] = useState({
        vanityLink: '',
        roomId: '',
        applicationId: '2445',
        staticUrl: null,
        freeformJson: {},
    });

    const [vanityLinkDataState, setVanityLinkDataState] = useState({
        vanityLinkId: '',
        clientName: '',
        emailLogin: true,
        guestOnlyMode: true,
    });

    const updateRoomState = (key, value) => {
        setRoomState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const updateVanityState = (key, value) => {
        setVanityState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const updateVanityLinkDataState = (key, value) => {
        setVanityLinkDataState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const { data: userGroups } = useQuery(['userGroups'], getUserGroups, {
        ...CACHE_QUERY_OPTIONS(),
    });

    const { data: roomTags } = useQuery(['roomTags'], getRoomTags, {
        ...CACHE_QUERY_OPTIONS(),
    });

    const { data: roomPrefabs } = useQuery(['roomPrefabs'], getRoomPrefabs, {
        ...CACHE_QUERY_OPTIONS(),
    });

    useEffect(() => {
        setButtonDisabled(validateRoomState());
    }, [roomState, vanityLink, vanityState]);

    const setRoomType = (value) => {
        updateRoomState('roomType', value);
    };

    const setRoomGroupId = (value) => {
        updateRoomState('groupId', value);
    };

    const handleUserClickOwner = (option) => {
        updateRoomState('ownerId', option.userId);
    };

    const handleRemoveTag = (tag) => {
        updateRoomState(
            'tags',
            roomState.tags.filter((selectedTag) => selectedTag !== tag)
        );
    };

    const handleDateChange = (date) => {
        if (moment(date).isSameOrAfter(moment(), 'day')) {
            updateRoomState('expiry', date);
        }
    };

    const handleRoomTagsClick = (option) => {
        if (roomState.tags.includes(option)) {
            updateRoomState(
                'tags',
                roomState.tags.filter((tag) => tag.id !== option.id)
            );
        } else {
            updateRoomState('tags', [...roomState.tags, option]);
        }
    };

    const AddRoomSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const tags = roomState.tags.map((tag) => tag.id);
        const groupId = userGroups.find((group) => group.level === roomState.groupId)?.id || null;
        let roomId = null;

        try {
            const responseAddRoom = await createRoomNew({ ...roomState, tags, groupId });

            if (responseAddRoom.status === 201) {
                roomId = responseAddRoom?.data?.roomId;

                if (vanityLink) {
                    const responseAddVanityLink = await createVanityLink({
                        ...vanityState,
                        roomId: responseAddRoom?.data?.roomId,
                    });

                    await createVanityLinkData({
                        ...vanityLinkDataState,
                        vanityLinkId: responseAddVanityLink?.vanityId,
                    });
                }
                setSuccessMessageBanner('Room Added Successfully');
            }
        } catch (err) {
            console.error('Error:', err);
            setBannerType('ERROR');
            setSuccessMessageBanner('ERROR creating new room. Try again or contact support');
        } finally {
            setLoading(false);
            if (roomId && bannerType !== 'ERROR') {
                navigate(`/console/rooms/${roomId}`);
            }
        }
    };

    const validateRoomState = () => {
        if (
            !roomState.roomType.trim() ||
            !roomState.ownerId.trim() ||
            !roomState.groupId.trim() ||
            !roomState.name.trim() ||
            !roomState.description.trim()
        ) {
            return true;
        }

        if (vanityLink && !vanityState.vanityLink) return true;

        return false;
    };

    return (
        <>
            <Banner
                width={'100%'}
                zIndex="1000"
                message={`${successMessageBanner}`}
                type={bannerType}
                openBanner={!!successMessageBanner}
                setOpenBanner={setSuccessMessageBanner}
                topDisplay
                showCloseButton
                gameScreen={false}
                top={'0px'}
                left={'0'}
            />
            {loading && (
                <LoadingIconContainer>
                    <LoadingIcon hover={false} />
                </LoadingIconContainer>
            )}
            <ModalContent>
                <GeneralContainer>
                    <Typography variant="h2PurpleHeading" margin={`0 0 20px 0`}>
                        ADD ROOM
                    </Typography>

                    <Card>
                        <Table onSubmit={AddRoomSubmit}>
                            <TableRow>
                                <TableColumn>
                                    <Label>Room Name*</Label>
                                    <InputBox
                                        type="text"
                                        id="roomName"
                                        placeholder="Enter Room Name"
                                        value={roomState.name}
                                        onChange={(e) => updateRoomState('name', e.target.value)}
                                    />
                                </TableColumn>
                            </TableRow>
                            <TableRow>
                                <TableColumn>
                                    <Label>Description*</Label>
                                    <InputBox
                                        type="text"
                                        id="description"
                                        placeholder="Enter Description"
                                        value={roomState.description}
                                        onChange={(e) => updateRoomState('description', e.target.value)}
                                    />
                                </TableColumn>
                            </TableRow>
                            <TableRow style={{ padding: '5px' }}>
                                <Label style={{ marginRight: '15px' }}>Visible on Nav Menu</Label>
                                <ToggleSwitch
                                    value={roomState.isVisibleInNavMenu}
                                    onChange={(value) => {
                                        updateRoomState('isVisibleInNavMenu', value);
                                    }}
                                />
                            </TableRow>
                            <TableRow>
                                <TableColumn>
                                    <Label>Room Code</Label>
                                    <InputBox
                                        type="text"
                                        id="roomCode"
                                        placeholder="Enter Room Code"
                                        value={roomState.code}
                                        onChange={(e) => updateRoomState('code', e.target.value)}
                                    />
                                </TableColumn>
                            </TableRow>
                            <TableRow>
                                <TableColumn>
                                    <Dropdown
                                        key={'roomTypeInput'}
                                        label="Room Type*"
                                        items={roomPrefabs?.map((item) => ({
                                            ...item,
                                            value: item.roomType,
                                        }))}
                                        selectedValue={roomState.roomType}
                                        setSelectedValue={setRoomType}
                                        placeholder="Select Room Type"
                                    />
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Dropdown
                                        key={'userGroupInput'}
                                        label="User Group*"
                                        items={userGroups?.map((item) => ({
                                            ...item,
                                            value: item.level,
                                        }))}
                                        selectedValue={roomState.groupId}
                                        setSelectedValue={setRoomGroupId}
                                        placeholder="Select User Group"
                                    />
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Dropdown
                                        key={'roomTagsInput'}
                                        label="Room Tags"
                                        items={roomTags?.map((item) => ({
                                            ...item,
                                            value: item.label,
                                        }))}
                                        selectedValue={roomState.tags}
                                        setSelectedValue={handleRoomTagsClick}
                                        placeholder="Select Room Tags"
                                        tags
                                        removeTags={handleRemoveTag}
                                    />
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <Label>Room Expiry</Label>
                                    <CustomDatetime
                                        onChange={handleDateChange}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat="HH:mm:ss"
                                        utc
                                        inputProps={{
                                            placeholder: 'Choose Date',
                                            value: roomState.expiry
                                                ? moment(roomState.expiry).tz(timezone).format('YYYY-MM-DD HH:mm:ss')
                                                : '',
                                        }}
                                        isValidDate={(current) => current.isAfter(minDate)}
                                    />
                                </TableColumn>
                            </TableRow>

                            <TableRow>
                                <TableColumn>
                                    <UserSearchDropdown
                                        label="Owner Email*"
                                        onSelection={handleUserClickOwner}
                                        selectedUser={roomState.ownerId}
                                    />
                                </TableColumn>
                            </TableRow>
                            {roomState.ownerId && (
                                <>
                                    <TableRow>
                                        <TableColumn>
                                            <Label>Owner Id</Label>
                                            <InputBox type="text" id="ownerName" value={roomState.ownerId} readOnly />
                                        </TableColumn>
                                    </TableRow>
                                </>
                            )}
                            <TableRow style={{ padding: '5px' }}>
                                <Label style={{ marginRight: '15px' }}>Create Vanity Link</Label>
                                <ToggleSwitch
                                    value={vanityLink}
                                    onChange={() => {
                                        setVanityLInk((prev) => !prev);
                                    }}
                                />
                            </TableRow>
                            {vanityLink && (
                                <>
                                    <TableRow>
                                        <TableColumn>
                                            <Label>Vanity Link Name*</Label>
                                            <InputBox
                                                type="text"
                                                id="vanityLinkName"
                                                placeholder="Enter Vanity Link Route -> tz-casino, discoverse"
                                                value={vanityState.vanityLink}
                                                onChange={(e) => {
                                                    updateVanityState('vanityLink', e.target.value);
                                                    updateVanityLinkDataState('clientName', e.target.value);
                                                }}
                                            />
                                        </TableColumn>
                                    </TableRow>
                                    <TableRow style={{ padding: '5px' }}>
                                        <Label style={{ marginRight: '15px' }}>Guest Only Sign-In</Label>
                                        <ToggleSwitch
                                            value={vanityLinkDataState.guestOnlyMode}
                                            onChange={(value) => {
                                                updateVanityLinkDataState('guestOnlyMode', value);
                                            }}
                                        />
                                    </TableRow>
                                </>
                            )}

                            <TableRow>
                                <Button
                                    type="submit"
                                    variant={'PinkButton'}
                                    buttonWidth={'100%'}
                                    disabled={buttonDisabled}
                                    label={'Add Room'}
                                />
                            </TableRow>
                        </Table>
                    </Card>
                </GeneralContainer>
            </ModalContent>
        </>
    );
};

export default AddRoomModal;

const LoadingIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${setColor.inputText};
`;

const Card = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 0;
    }
`;

const GeneralContainer = styled.div`
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Table = styled.form`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;

const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const InputBox = styled.input`
    padding: 10px;
    border: ${(props) => (props.errorMessage ? `1px solid ${setColor.errorColor}` : `1px solid ${setColor.grey1}`)};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: ${(props) => (props.width ? props.width : '100%')};
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage ? '0.5rem' : '0rem')};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const CustomDatetime = styled(Datetime)`
    .rdtDisabled {
        cursor: not-allowed !important;
        color: #ccc;
    }
    .rdt {
        background-color: #504c5c;
    }
    .rdtPicker {
        background-color: #504c5c;
        width: 100%;
        border-radius: 5px;
    }
    .rdtTimeToggle {
        background-color: #504c5c;
    }
    .rdtDays {
        background-color: #504c5c;
    }
    .rdtTime {
        background-color: #504c5c;
    }
    input {
        padding: 10px;
        border: ${(props) => (props.errorMessage ? `1px solid ${setColor.errorColor}` : `1px solid ${setColor.grey1}`)};
        border-radius: 5px;
        color: ${setColor.inputText};
        margin: 0rem 0rem 0rem 0rem;
        width: ${(props) => (props.width ? props.width : '100%')};
        background-color: transparent;
        margin-bottom: ${(props) => (props.errorMessage ? '0.5rem' : '0rem')};
        &:disabled {
            background-color: ${setColor.grey1};
        }
    }
`;
