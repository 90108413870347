import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Title from '../../components/Title';
import Body from '../../layout/Body';
import { formatDateTimeWithTimeZone } from '../../../../utils/generalUtils';
import { API_GET, API_PUT } from '../../../../config/axiosConfig';
import { setColor, setFont } from '../../../../assets/styles/styles';
import { ReactComponent as ChevronIcon } from '../../../../assets/images/icons/Chevron.svg';
import { ReactComponent as Checkmark } from '../../../../assets/images/icons/Checkmark.svg';
import { ReactComponent as NullIcon } from '../../../../assets/images/icons/System.svg';
import { ReactComponent as Cross } from '../../../../assets/images/icons/Cross.svg';
import { ReactComponent as Link } from '../../../../assets/images/icons/link.svg';
import { default as Button } from '../../../../components/common/button/Button';
import Typography from '../../../../components/common/text/Typography';
import ToggleSwitch from '../../../../components/common/switchToggle/ToggleSwitch';
import { ReactComponent as NotFoundIcon } from '../../../../assets/images/icons/NotFound.svg';
import Modal from '../../components/Modal';
import DisconnectModal from './DisconnectModal';
import ConnectAddModal from './ConnectAddModal';
import DeleteMerchantModal from './DeleteMerchantModal';
import { getAccountLink, getMerchantProducts } from '../api/merchantController';
import LinkModal from './LinkModal';
import ProductsTable from './ProductsTable';

const MerchantDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [merchantData, setMerchantData] = useState();
    const [length, setLength] = useState(0);
    const [editedMerchantName, setEditedMerchantName] = useState('');
    // const [editedStatus, setEditedStatus] = useState('');
    const [editedType, setEditedType] = useState('');
    const [editedEmail, setEditedEmail] = useState('');
    const [editedStripeClientId, setEditedStripeClientId] = useState('');
    const [editedUnitNumber, setEditedUnitNumber] = useState('');
    const [editedStreet, setEditedStreet] = useState('');
    const [editedCity, setEditedCity] = useState('');
    const [editedCountry, setEditedCountry] = useState('');
    const [editedPostalCode, setEditedPostalCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [label, setLabel] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const [fieldsObj, setFieldsObj] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [requirementsMessage, setRequirementsMessage] = useState([]);
    const [accountLink, setAccountLink] = useState('');
    const [isModalLinkOpen, setIsModalLinkOpen] = useState(false);
    const [isRequirementsMessage, setIsRequirementsMessage] = useState(false);
    const [stripeStatus, setStripeStatus] = useState('');
    const [products, setProducts] = useState([]);

    const deleteMerchantClick = () => {
        setIsModalDeleteOpen(true);
    };

    const openAddModal = () => {
        setIsModalAddOpen(true);
    };

    const getAccountLinkClick = async () => {
        const resp = await getAccountLink(editedStripeClientId, id);
        if (resp.status === 200) {
            setIsModalLinkOpen(true);
            const accountLink = resp.accountLink.url;
            setAccountLink(accountLink);
        }
    };

    const getProductData = async () => {
        const resp = await getMerchantProducts(id);
        if (resp.status === 200) {
            setProducts(resp.data.products);
        }
        if (resp?.response?.status === 404) {
            setProducts([]);
        }
    };

    const stripeAccountInfo = async (editedStripeClientId) => {
        try {
            if (editedStripeClientId !== '') {
                const url = `${process.env.REACT_APP_CORE_API_URI}/web/stripe/connect-merchant/${editedStripeClientId}`;
                const response = await API_GET(url);
                if (response.status === 200 && response.data.account.id) {
                    const requirements = response.data.account.requirements?.currently_due;
                    setRequirementsMessage(requirements);

                    if (requirements && requirements.length === 0) {
                        setStripeStatus('Complete');
                    } else {
                        setStripeStatus('Incomplete');
                    }
                }
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const toggleRequirementsMessage = () => {
        setIsRequirementsMessage(!isRequirementsMessage);
    };

    const handleToggleChange = (isChecked) => {
        if (isChecked) {
            setIsModalOpen(true);
        }
    };

    const fetchData = async (url) => {
        try {
            setIsLoading(true);
            const response = await API_GET(url);
            if (response.data.merchant) {
                const createdDateTime = formatDateTimeWithTimeZone(response.data.merchant.createdAt);
                const updatedDateTime = formatDateTimeWithTimeZone(response.data.merchant.updatedAt);
                response.data.merchant.createdDateTime = createdDateTime;
                response.data.merchant.updatedDateTime = updatedDateTime;
                setMerchantData(response.data);
                setEditedMerchantName(response.data.merchant.merchantName);
                // setEditedStatus(response.data.merchant.status);
                setEditedEmail(response.data.merchant.merchantContactEmail);
                setEditedType(response.data.merchant?.merchantBusinessType);
                setEditedStripeClientId(response.data.merchant?.stripeClientId);
                setEditedUnitNumber(response.data.merchant?.unitNumber);
                setEditedStreet(response.data.merchant?.street);
                setEditedCity(response.data.merchant?.city);
                setEditedCountry(response.data.merchant?.country);
                setEditedPostalCode(response.data.merchant?.postalCode);
                setLength(1);
                const fields = [
                    { label: 'Name', key: 'name', value: editedMerchantName, rowNo: 1 },
                    { label: 'Business Type', key: 'merchantBusinessType', value: editedType, rowNo: 1 },
                    { label: 'Email', key: 'merchantContactEmail', value: editedEmail, rowNo: 2 },
                    // { label: 'Stripe Connect Account Id', key: 'stripeClientId', value: editedStripeClientId, rowNo: 2 },
                    // { label: 'Status', key: 'status', value: editedStatus, rowNo: 3 },
                    { label: 'Unit Number', key: 'unitNumber', value: editedUnitNumber, rowNo: 2 },
                    { label: 'Street Name', key: 'street', value: editedStreet, rowNo: 3 },
                    { label: 'City', key: 'city', value: editedCity, rowNo: 3 },
                    { label: 'Country', key: 'country', value: editedCountry, rowNo: 4 },
                    { label: 'Postal Code', key: 'postalCode', value: editedPostalCode, rowNo: 4 },
                ];
                setFieldsObj(fields);
                setIsLoading(false);
                stripeAccountInfo(response.data.merchant?.stripeClientId);
                getProductData();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMerchantData('');
            setIsLoading(false);
        }
    };

    const handleCloseClick = (label) => {
        if (hasChanges) {
            setErrorMessage(`Changes not saved for ${label.toLowerCase()}`);
            setHasChanges(false);
            fetchData(`${process.env.REACT_APP_CORE_API_URI}/web/merchant/${id}`);
        }
        setLabel('');
        setErrorMessage('');
    };

    const handleCheckmarkClick = async (label) => {
        setLabel(label);
        if (!hasChanges) {
            setErrorMessage(`No changes to update for ${label.toLowerCase()}`);
        } else {
            setErrorMessage('');
        }
        const updateBody = {};
        const fieldToUpdate = fieldsObj.find((field) => field.key === label);

        if (fieldToUpdate && hasChanges) {
            const fieldName = fieldToUpdate.key;
            const fieldValue = getFieldValue(fieldToUpdate);
            updateBody[fieldName] = fieldValue;
        }

        if (Object.keys(updateBody).length !== 0) {
            try {
                const response = await API_PUT(`${process.env.REACT_APP_CORE_API_URI}/web/merchant/${id}`, {
                    ...updateBody,
                });

                if (response.status === 200) {
                    setSuccessMessage(`Successfully updated ${label.toLowerCase()}`);
                } else {
                    setErrorMessage(`Failed to update ${label.toLowerCase()}`);
                }
                setHasChanges(false);
                fetchData(`${process.env.REACT_APP_CORE_API_URI}/web/merchant/${id}`);
            } catch (error) {
                console.error('Error updating data:', error);
                if (error.response.status === 422) {
                    console.log('response here', error.response.data.message[0]);
                    setErrorMessage(error.response.data.message[0]);
                } else {
                    setErrorMessage(`Failed to update ${label.toLowerCase()}`);
                }
                fetchData(`${process.env.REACT_APP_CORE_API_URI}/web/merchant/${id}`);
            }
        }
    };

    useEffect(() => {
        if (errorMessage || successMessage) {
            const timer = setTimeout(() => {
                setErrorMessage('');
                setSuccessMessage('');
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [errorMessage, successMessage]);

    useEffect(() => {
        const url = `${process.env.REACT_APP_CORE_API_URI}/web/merchant/${id}`;
        fetchData(url);
    }, [id]);

    const handleBack = () => {
        // window.history.back();
        navigate(`/console/merchants`);
    };

    const getFieldValue = (field) => {
        switch (field.key) {
            case 'name':
                return editedMerchantName;
            // case 'status':
            //     return editedStatus;
            case 'merchantBusinessType':
                return editedType;
            case 'merchantContactEmail':
                return editedEmail;
            // case 'stripeClientId':
            //     return editedStripeClientId;
            case 'unitNumber':
                return editedUnitNumber;
            case 'street':
                return editedStreet;
            case 'city':
                return editedCity;
            case 'country':
                return editedCountry;
            case 'postalCode':
                return editedPostalCode;
            default:
                return '';
        }
    };

    const handleFieldChange = (e, field) => {
        setHasChanges(true);
        switch (field.key) {
            case 'name':
                setEditedMerchantName(e.target.value);
                break;
            // case 'status':
            // setEditedStatus(e.target.value);
            // break;
            case 'merchantBusinessType':
                setEditedType(e.target.value);
                break;
            case 'merchantContactEmail':
                setEditedEmail(e.target.value);
                break;
            // case 'stripeClientId':
            //     setEditedStripeClientId(e.target.value);
            //     break;
            case 'unitNumber':
                setEditedUnitNumber(e.target.value);
                break;
            case 'street':
                setEditedStreet(e.target.value);
                break;
            case 'city':
                setEditedCity(e.target.value);
                break;
            case 'country':
                setEditedCountry(e.target.value);
                break;
            case 'postalCode':
                setEditedPostalCode(e.target.value);
                break;
            default:
                break;
        }
    };

    const uniqueRowNumbers = [...new Set(fieldsObj.map((field) => field.rowNo))];

    return (
        <>
            <Title title={'MERCHANT OVERVIEW'} description={'Manage all merchants in  Intraverse.'} />
            <UserConsoleText style={{ marginBottom: '1.5rem' }} onClick={handleBack}>
                <StyledIconPrev /> BACK TO TABLE
            </UserConsoleText>
            <Body isLoading={isLoading}>
                <>
                    {merchantData && (
                        <>
                            <CardTable>
                                <TableRow>
                                    <TableColumn>
                                        <Typography variant="PinkText">{merchantData.merchant.merchantId}</Typography>
                                    </TableColumn>

                                    <TableColumnButton>
                                        <Button
                                            label="DELETE"
                                            buttonWidth="auto"
                                            onClick={deleteMerchantClick}
                                            border={`${setColor.accent4}`}
                                        />
                                        {isModalDeleteOpen && (
                                            <Modal
                                                isOpen={isModalDeleteOpen}
                                                setIsOpen={setIsModalDeleteOpen}
                                                children={
                                                    <DeleteMerchantModal
                                                        id={id}
                                                        closeDeleteModal={() => setIsModalDeleteOpen(false)}
                                                        merchantName={editedMerchantName}
                                                    />
                                                }
                                            />
                                        )}
                                    </TableColumnButton>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <strong>CREATED:</strong> {merchantData.merchant.createdDateTime}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <strong>UPDATED:</strong> {merchantData.merchant.updatedDateTime}
                                    </TableColumn>
                                </TableRow>
                            </CardTable>

                            <Table>
                                {uniqueRowNumbers.map((rowNo) => (
                                    <Row key={rowNo}>
                                        {fieldsObj
                                            .filter((field) => field.rowNo === rowNo)
                                            .map((field) => (
                                                <React.Fragment key={field.key}>
                                                    <Column errorMessage={errorMessage}>
                                                        <Label>{field.label}</Label>
                                                        {label === field.key && errorMessage && (
                                                            <MessageDesc type="error">{errorMessage}</MessageDesc>
                                                        )}
                                                        {label === field.key && successMessage && (
                                                            <MessageDesc type="success">{successMessage}</MessageDesc>
                                                        )}

                                                        <InputBox
                                                            type="text"
                                                            id={field.key}
                                                            value={getFieldValue(field)}
                                                            onChange={(e) => handleFieldChange(e, field)}
                                                        />
                                                    </Column>

                                                    <Column small>
                                                        <IconDiv>
                                                            <StyledCheckmark
                                                                onClick={() => handleCheckmarkClick(field.key)}
                                                            />
                                                            <StyledCloseIcon
                                                                onClick={() => handleCloseClick(field.key)}
                                                            />
                                                        </IconDiv>
                                                    </Column>
                                                </React.Fragment>
                                            ))}
                                    </Row>
                                ))}
                            </Table>

                            {editedStripeClientId && (
                                <CardTable>
                                    <TableRow>
                                        <Column errorMessage={errorMessage}>
                                            <Typography variant="PinkText">Stripe Connect Account</Typography>
                                            <Typography variant="ConsoleDescription" margin={'1rem 0 0 0'}>
                                                {' '}
                                                <strong> {editedStripeClientId} </strong>{' '}
                                            </Typography>

                                            {stripeStatus === 'Complete' && (
                                                <Typography variant="ConsoleDescription" margin={'0.5rem 0 0 0'}>
                                                    {' '}
                                                    <strong> {stripeStatus} </strong> <StyledCheckmarkGreen />{' '}
                                                </Typography>
                                            )}

                                            {stripeStatus === 'Incomplete' && (
                                                <Typography variant="ConsoleDescription">
                                                    {' '}
                                                    Action Required!
                                                    <NullIconImage
                                                        alt="Null Icon"
                                                        onClick={toggleRequirementsMessage}
                                                    />
                                                    {requirementsMessage.length > 0 && (
                                                        <>
                                                            <Message
                                                                show={
                                                                    isRequirementsMessage &&
                                                                    requirementsMessage.length > 0
                                                                }
                                                            >
                                                                {isRequirementsMessage &&
                                                                requirementsMessage.length > 0 ? (
                                                                    requirementsMessage.map((requirement) => (
                                                                        <Typography
                                                                            variant="ConsoleDescription"
                                                                            style={{ margin: '1rem 0 0 0' }}
                                                                        >
                                                                            {requirement}
                                                                        </Typography>
                                                                    ))
                                                                ) : (
                                                                    <Typography
                                                                        variant="ConsoleDescription"
                                                                        style={{ margin: '1rem 0 0 0' }}
                                                                    >
                                                                        No requirements found.
                                                                    </Typography>
                                                                )}
                                                            </Message>
                                                        </>
                                                    )}
                                                </Typography>
                                            )}
                                        </Column>

                                        <ColumnLink>
                                            <TableColumnButton>
                                                <ToggleSwitch onChange={handleToggleChange} />
                                            </TableColumnButton>

                                            {isModalOpen && (
                                                <>
                                                    <Modal
                                                        isOpen={isModalOpen}
                                                        setIsOpen={setIsModalOpen}
                                                        children={
                                                            <DisconnectModal
                                                                id={id}
                                                                closeModal={() => setIsModalOpen(false)}
                                                                merchantName={editedMerchantName}
                                                                editedStripeClientId={editedStripeClientId}
                                                            />
                                                        }
                                                    />
                                                </>
                                            )}

                                            {stripeStatus !== 'Complete' && (
                                                <>
                                                    <TableColumnButton>
                                                        <LinkIcon onClick={getAccountLinkClick} />
                                                    </TableColumnButton>
                                                    {isModalLinkOpen && (
                                                        <Modal
                                                            isOpen={isModalLinkOpen}
                                                            setIsOpen={setIsModalLinkOpen}
                                                            children={
                                                                <LinkModal
                                                                    url={accountLink}
                                                                    closeModal={() => setIsModalLinkOpen(false)}
                                                                />
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </ColumnLink>
                                    </TableRow>
                                </CardTable>
                            )}

                            {!editedStripeClientId && (
                                <CardTable>
                                    <TableRow>
                                        <Column errorMessage={errorMessage}>
                                            <Typography variant="PinkText">Stripe Connect Account</Typography>
                                            <Typography variant="ConsoleDescription" margin={'1rem 0 0 0'}>
                                                {' '}
                                                No Stripe Connect Account{' '}
                                            </Typography>
                                        </Column>

                                        <TableColumnButton>
                                            <Button
                                                label="CONNECT"
                                                buttonWidth="auto"
                                                onClick={() => openAddModal()}
                                                border={`${setColor.accent4}`}
                                            />
                                        </TableColumnButton>

                                        {isModalAddOpen && (
                                            <>
                                                <Modal
                                                    isOpen={isModalAddOpen}
                                                    setIsOpen={setIsModalAddOpen}
                                                    children={
                                                        <ConnectAddModal
                                                            id={id}
                                                            merchantName={editedMerchantName}
                                                            email={editedEmail}
                                                            closeAddModal={() => setIsModalAddOpen(false)}
                                                        />
                                                    }
                                                />
                                            </>
                                        )}
                                    </TableRow>
                                </CardTable>
                            )}

                            <CardTable>
                                <TableRow>
                                    <Column errorMessage={errorMessage}>
                                        <Typography variant="PinkText">Products</Typography>
                                        {products.length === 0 && (
                                            <Typography variant="ConsoleDescription" margin={'1rem 0 0 0'}>
                                                {' '}
                                                No Related Products Found.{' '}
                                            </Typography>
                                        )}
                                    </Column>
                                </TableRow>
                                <TableRow>
                                    {products.length > 0 && (
                                        <>
                                            <ProductsTable productsData={products} />
                                        </>
                                    )}
                                </TableRow>
                            </CardTable>
                        </>
                    )}
                    {length === 0 && <NotFoundImage alt="Not Found" />}
                </>
            </Body>
        </>
    );
};

export default MerchantDetails;

const UserConsoleText = styled.h1`
    font-family: ${setFont.barlowCondensed}, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    color: ${setColor.accent4};
    position: relative;
    cursor: pointer;
`;

const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin-top: 12px !important;
    transform: rotate(90deg) !important;
`;

const IconDiv = styled.div`
    justify-content: center;
    align-items: center;
    margin: 2.5rem 2rem 2rem 1rem;
    width: fit-content;
    color: ${setColor.accent4};
    justify-content: flex-end;
`;

const StyledCheckmark = styled(Checkmark)`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
        #Union {
            fill: ${setColor.greenLight};
        }
    }
`;

const StyledCheckmarkGreen = styled(Checkmark)`
    width: 15px;
    height: 15px;
    #Union {
        fill: ${setColor.greenLight};
    }
`;

const StyledCloseIcon = styled(Cross)`
    border-radius: 5px !important;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover {
        #Union {
            fill: ${setColor.lightPink};
        }
    }
`;

const CardTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem 1rem 1rem;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
    background: ${setColor.onHoverTextcolor2};
    border-radius: 5px;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnButton = styled.div`
    margin-right: 0.5rem;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Table = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
`;

const Row = styled.div`
    display: flex;
`;

const Column = styled.div`
    margin-top: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0')};
    flex: ${(props) => (props.small ? '0.5' : '2')};
    width: ${(props) => (props.small ? '5%' : '95%')};
    @media (max-width: 768px) {
        flex: ${(props) => (props.small ? '1' : '2')};
    }
`;

const ColumnLink = styled.div`
    margin: 0rem 0rem 0rem 0rem;
    flex: 1;
    width: 40%;
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;
const MessageDesc = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    color: ${(props) => (props.type === 'success' ? setColor.successColor : setColor.errorColor)};
`;

const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    height: 50%;
    width: ${(props) => (props.width ? props.width : '100%')};
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
`;

const NotFoundImage = styled(NotFoundIcon)`
    width: 300px;
    height: 300px;

    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const NullIconImage = styled(NullIcon)`
    width: 15px;
    height: 15px;
    margin: 1rem 0rem 0rem 0.5rem;
`;
const Message = styled.div`
    position: relative;
    background-color: ${setColor.grey1};
    color: ${setColor.inputBgColor};
    border-radius: 4px;
    padding: 10px;
    display: ${({ show }) => (show ? 'block' : 'none')};
`;

const LinkIcon = styled(Link)`
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: 1rem 1rem 1rem 1rem;
`;
