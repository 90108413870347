import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query'; 
import styled from 'styled-components';
import moment from 'moment';
import { setColor } from '../../../../../assets/styles/styles';
import Title from '../../../components/Title';
import Body from '../../../layout/Body';
import { DEFAULT_QUERY_OPTIONS } from '../../../../../constants';
import Typography from '../../../../../components/common/text/Typography';
import { default as Button } from '../../../../../components/common/button/Button';
import { ReactComponent as ChevronIcon } from '../../../../../assets/images/icons/Chevron.svg';
import { getRoomById, getRoomRelations } from '../api/roomController';
import DataTable from '../../../../../components/common/dataTable/DataTable';
import Modal from '../../../components/Modal';
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/icons/deleteIcon.svg';
import Pagination from '../../../../../components/common/pagination/Pagination';
import AddEditSubroom from './AddEditSubroom';
import { ReactComponent as dustbin } from '../../../../../assets/images/icons/dustbin.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/images/icons/editIcon.svg';
import { formatDateTimeWithTimeZone } from '../../../../../utils/generalUtils';
import DeleteModal from './DeleteModal';

const RoomRelationsPage = () => {
    const { id } = useParams();
    const ROWS_PER_PAGE = 5;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [visibleRows, setVisibleRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedObject, setSelectedObject] = useState({});
    const [openAddSubroomToRoomModal, setOpenAddSubroomToRoomModal] = useState(false);
    const [isRelationDeleteModal, setIsRelationDeleteModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);

    const handleBack = () => {
        navigate(`/console/rooms/${id}`);
    };

    const { data: roomInfo, error: roomByIdError, isLoading: roomByIdLoading, refetch } = useQuery(
        ['roomInfo', id],
        () => getRoomById(id),
        {
            enabled: !!id, 
            ...DEFAULT_QUERY_OPTIONS(),  
        }
    );

    const { data: roomRelations, error: roomRelationsError, isLoading: roomRelationsLoading, refetch: refetchRoomRelations } = useQuery(
        ['allRoomRelations', id],
        () => getRoomRelations(id),
        {
            enabled: !!id,
            ...DEFAULT_QUERY_OPTIONS(),
        }
    );

    const addSubroomClick = async () => {
        setOpenAddSubroomToRoomModal(true);
    };

    useEffect(() => {
        const fetchSubroomNames = async () => {
            if (roomRelations) {
                setIsLoading(true);
                const roomRelationsWithNames = await Promise.all(
                    roomRelations.map(async (relation) => {
                        const respData = await getRoomById(relation.subroomRoomId);
                        return {
                            ...relation,
                            createdAt: formatDateTimeWithTimeZone(relation.createdAt),
                            updatedAt: formatDateTimeWithTimeZone(relation.updatedAt),
                            subroomRoomName: respData.name, // Add room name from response
                        };
                    })
                );
                setTotalPages(Math.ceil(roomRelationsWithNames.length / ROWS_PER_PAGE));
                setVisibleRows(roomRelationsWithNames.slice((currentPage - 1) * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE));
                setIsLoading(false);
            }
        };

        fetchSubroomNames();
    }, [roomRelations, currentPage]);

    const handleRemoveSubroomClick = async (row) => {
        setSelectedObject(row);
        setIsRelationDeleteModal(true);
    };

    const handleEditClick = (row) => {
        setSelectedObject(row);
        setIsEditModal(true);
    };

    const renderRow = (row) => {
        const buttonDelSubRoom = (
            <StyledDustbinIcon onClick={() => handleRemoveSubroomClick(row)} />
        );
        const buttonEdit = (
            <StyledEditIcon onClick={() => handleEditClick(row)} />
        );
        return [
            row.relationshipId, 
            row.subroomRoomId, 
            row.subroomRoomName, 
            row.subroomRoomSettingsId, 
            row.createdAt,
            <>{buttonEdit}</>,
            <>{buttonDelSubRoom}</>,
        ];
    };

    return (
        <>
            <Title
                title={'ROOM RELATIONS'}
                description={
                    'Manage the subrooms associated with this room.'
                }
            />

            <UserConsoleText style={{ marginBottom: '1rem' }} onClick={handleBack}>
                <StyledIconPrev /> BACK TO TABLE
            </UserConsoleText>
            
            <Body isLoading={isLoading}>
                <>
                    <CardTable>
                        <TableRow>
                            <TableColumn>
                                <Typography variant="PinkText" margin={'5px'}>
                                    {roomInfo?.name ? `${roomInfo?.name}: RELATIONS` : ''}
                                </Typography>
                                <Typography variant="ConsoleDescription" margin={'5px'}>
                                    <strong>Parent Room ID: </strong> {id}
                                </Typography>
                            </TableColumn>

                            <TableColumnButton>
                                <Button
                                    variant={'SmallButton'}
                                    label="ADD SUBROOM"
                                    buttonWidth="auto"
                                    margin="0rem 1rem 0rem 0rem"
                                    onClick={addSubroomClick}
                                    border={`${setColor.accent4}`}
                                />
                            </TableColumnButton>
                        </TableRow>
                    </CardTable>
                    
                    {roomRelations && (
                        <>
                            <DataTable
                                headers={['RELATIONSHIP ID', 'SUBROOM ID', 'SUBROOM NAME', 'SUBROOM SETTING ID', 'CREATED AT']}
                                data={visibleRows}
                                renderRow={renderRow}
                                isHover={false}
                            />
                            <Pagination
                                totalNumber={roomRelations.length}
                                visibleRows={visibleRows}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                tag={'Subrooms'}
                            />
                        
                            {isRelationDeleteModal && (
                                <Modal
                                    isOpen={isRelationDeleteModal}
                                    setIsOpen={setIsRelationDeleteModal}
                                    children={
                                        <DeleteModal
                                            id={id}
                                            requestType={'subroom'}
                                            selectedRoomUser={selectedObject}
                                            closeModal={() => setIsRelationDeleteModal(false)}
                                        />
                                    }
                                />
                            )}

                            {openAddSubroomToRoomModal && (
                                <Modal
                                    isOpen={openAddSubroomToRoomModal}
                                    setIsOpen={setOpenAddSubroomToRoomModal}
                                    children={
                                        <AddEditSubroom
                                            roomId={id}
                                            type={'add'}
                                            selectedObject={selectedObject}
                                            closeModal={() => setOpenAddSubroomToRoomModal(false)}
                                            roomRelations={roomRelations}
                                        />
                                    }
                                />
                            )}

                            {isEditModal && (
                                <Modal
                                    isOpen={isEditModal}
                                    setIsOpen={setIsEditModal}
                                    children={
                                        <AddEditSubroom
                                            roomId={id}
                                            type={'edit'}
                                            selectedObject={selectedObject}
                                            closeModal={() => setIsEditModal(false)}
                                            roomRelations={roomRelations}
                                        />
                                    }
                                />
                            )}
                        </>
                    )}
                 </> 
            </Body>
            
        </>
    );
};

export default RoomRelationsPage;

const UserConsoleText = styled.h1`
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    color: ${setColor.accent4};
    position: relative;
    cursor: pointer;
`;

const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin-top: 12px !important;
    transform: rotate(90deg) !important;
`;

const CardTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem 1rem 1rem;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
    background: ${setColor.onHoverTextcolor2};
    border-radius: 5px;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnButton = styled.div`
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const StyledDustbinIcon = styled(dustbin)`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin: -2px 10px;
`;

const StyledEditIcon = styled(EditIcon)`
    width: 15px;
    height: 15px;
    cursor: pointer;
`;