import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { setColor } from '../../../assets/styles/styles';
import { getAllUsers } from '../admin/rooms/api/roomController';
import { CACHE_QUERY_OPTIONS } from '../../../constants';

const UserSearchDropdown = ({ label, onSelection, selectedUser }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchTermUserOwner, setSearchTermUserOwner] = useState('');
    const [filteredUsersOwner, setFilteredUsersOwner] = useState([]);

    const dropdownRef = useRef(null);

    const { data: allUsers } = useQuery(['allUsers'], () => getAllUsers(), {
        ...CACHE_QUERY_OPTIONS(),
    });

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSearchTermChangeOwner = (e) => {
        setSearchTermUserOwner(e.target.value);
        if (allUsers) {
            const filtered = allUsers.filter((user) =>
                user.email.toLowerCase().includes(searchTermUserOwner.toLowerCase())
            );
            setFilteredUsersOwner(filtered);
            setIsDropdownOpen(true);
        }
    };

    return (
        <DropdownContainer ref={dropdownRef}>
            <Label>{label}</Label>
            <InputBox
                type="text"
                placeholder="Type to search user email"
                value={searchTermUserOwner}
                onChange={handleSearchTermChangeOwner}
            />
            {isDropdownOpen && (
                <DropdownContentUsers>
                    {filteredUsersOwner.length > 0 ? (
                        filteredUsersOwner.map((option) => (
                            <DropdownItem
                                key={option.userId}
                                onClick={() => {
                                    onSelection(option);
                                    setSearchTermUserOwner(option.email);
                                    setIsDropdownOpen(false);
                                }}
                                isSelected={selectedUser === option.email}
                            >
                                {option.email}
                            </DropdownItem>
                        ))
                    ) : (
                        <MessageNote>No data match</MessageNote>
                    )}
                </DropdownContentUsers>
            )}
        </DropdownContainer>
    );
};

UserSearchDropdown.propTypes = {
    label: PropTypes.string,
    onSelection: PropTypes.func,
    selectedUser: PropTypes.node,
};

export default UserSearchDropdown;

const MessageNote = styled.div`
    font-size: 14px;
    color: ${setColor.inputText};
`;

const DropdownContainer = styled.div`
    position: relative;
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;

const DropdownContentUsers = styled.div`
    width: 50px;
    right: 0;
    padding: 5px;
    font-family: 'Barlow-Regular', Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const InputBox = styled.input`
    padding: 10px;
    border: ${(props) => (props.errorMessage ? `1px solid ${setColor.errorColor}` : `1px solid ${setColor.grey1}`)};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: ${(props) => (props.width ? props.width : '100%')};
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage ? '0.5rem' : '0rem')};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;
