import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from '@emotion/styled';
import { setColor } from '../../../../../assets/styles/styles';
import Typography from '../../../../../components/common/text/Typography';
import { default as Button } from '../../../../../components/common/button/Button';
import { getAllPermissions, addRole, addPermissionintoRole } from '../api/rolesPermissionsController';
import { ReactComponent as DropdownIcon } from '../../../../../assets/images/icons/Chevron.svg';
import Banner from '../../../../../components/layout/ui/Banner';
import { ReactComponent as dustbin } from '../../../../../assets/images/icons/dustbin.svg';

const AddModal = ({ requestType, roleId, closeAddModal }) => {
    const [successMessageBanner, setSuccessMessageBanner] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOptionType, setSelectedOptionType] = useState('');
    const [isDropdownOpenType, setIsDropdownOpenType] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [displayPermission, setDisplayPermission] = useState('Select Permission');
    const [displayType, setDisplayType] = useState('Select Type');
    const [name, setName] = useState('');

    const options = [
        { id: 'admin-console', value: 'Admin Console'},
        { id: 'room', value: 'Room'},
    ];

    const { data: allPermissions, error, isLoading } = useQuery(['permissions'], getAllPermissions);
    useEffect(() => {
        if (successMessageBanner) {
            const timer = setTimeout(() => {
                closeAddModal();
                window.location.reload();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessageBanner]);

    const handleOptionClick = (option) => {
        if (!selectedPermissions.some(p => p.id === option.id)) {
            setSelectedPermissions([...selectedPermissions, option]);
            setDisplayPermission(`${option.name}`);
        }
    };

    const removePermission = (id) => {
        setSelectedPermissions(selectedPermissions.filter(p => p.id !== id));
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleTypeClick = (option) => {
        setIsDropdownOpenType(false);
        setSelectedOptionType(option.id);
        setDisplayType(option.value);
    };

    const toggleDropdownType = () => {
        setIsDropdownOpenType(!isDropdownOpenType);
    };

    const AddRoleClick = async () => {    
        const resp = await addRole(name);
        if( resp.status === 201 ){
            const roleId = resp.data.roleId;
            const permissionIds = selectedPermissions.map(p => p.id);
            const respPermissionAdded = await addPermissionintoRole(roleId, permissionIds);
            if( respPermissionAdded.status === 201 ){
                setSuccessMessageBanner(`Role added successfully with ${permissionIds.length} permissions. Page will reload in 3 seconds`);
            }
        }
        setSelectedPermissions([]);
    }

    const AddPermissionClick = async () => {  
        const permissionIds = selectedPermissions.map(p => p.id);
        const respPermissionAdded = await addPermissionintoRole(roleId, permissionIds);
        if( respPermissionAdded.status === 201 ){
            setSuccessMessageBanner(`${permissionIds.length} permissions added successfully. Page will reload in 3 seconds.`);
        }
        setSelectedPermissions([]);
    }
    return (
        <>
            <ModalContent>
                <Banner
                    width={'100%'}
                    zIndex="1000"
                    message={`${successMessageBanner}`}
                    type={'SUCCESS'}
                    openBanner={!!successMessageBanner}
                    setOpenBanner={setSuccessMessageBanner}
                    topDisplay
                    showCloseButton
                    gameScreen={false}
                    top={'0px'}
                    left={'0'}
                />
                {requestType === 'roles' && (
                    <GeneralContainer>
                        <Typography variant="h2PurpleHeading" fontSize={'24px'} margin={`1rem 0 0 0`}>
                            Add New Role
                        </Typography>
        
                        <Card>
                            <Table>
                                <TableRow>
                                    <TableColumn>
                                        <Label>Role Name</Label>
                                        <InputBox
                                            type="text"
                                            id="name"
                                            value={name}
                                            placeholder="Enter Role Name"
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </TableColumn>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <Label>Role Type</Label>
                                        <DropdownButton onClick={toggleDropdownType} isopen={isDropdownOpenType.toString()}>                                            
                                            <TitleText>
                                                {displayType}
                                            </TitleText>

                                            <StyledDropdownIcon isopen={isDropdownOpenType.toString()} />
                                            {isDropdownOpenType && (
                                                <DropdownContent>
                                                    {options.map(option => (
                                                        <DropdownItem
                                                            key={option.id}
                                                            onClick={() => handleTypeClick(option)}
                                                            isSelected={selectedOptionType === option.value}
                                                        >
                                                            {option.value}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownContent>
                                            )}
                                        </DropdownButton>
                                    </TableColumn>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <Label>Permission</Label>
                                        <DropdownButton onClick={toggleDropdown} isopen={isDropdownOpen.toString()}>                                            
                                            <TitleText>
                                                {displayPermission}
                                            </TitleText>

                                            <StyledDropdownIcon isopen={isDropdownOpen.toString()} />
                                            {isDropdownOpen && (
                                                <DropdownContent>
                                                    {allPermissions.map(option => (
                                                        <DropdownItem
                                                            key={option.id}
                                                            onClick={() => handleOptionClick(option)}
                                                            isSelected={selectedPermissions.some(p => p.id === option.id)}
                                                        >
                                                            {option.name} 
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownContent>
                                            )}
                                        </DropdownButton>
                                    </TableColumn>
                                </TableRow>

                                {selectedPermissions.length > 0 && (
                                    <TableRow>
                                        <TableColumn>
                                            <Label>Selected Permissions</Label>
                                            <ul>
                                                {selectedPermissions.map(permission => (
                                                    <StyledListItem key={permission.id}>
                                                        {permission.type}: {permission.name}
                                                        <StyledDeleteIcon onClick={() => removePermission(permission.id)} />
                                                    </StyledListItem>
                                                ))}
                                            </ul>
                                        </TableColumn>
                                    </TableRow>
                                )}

                                <TableRow>
                                    <Button
                                        variant={'PinkButton'}
                                        buttonWidth={'100%'}
                                        disabled={name === '' || selectedOptionType === '' || selectedPermissions.length === 0}
                                        label={'Add Role'}
                                        onClick={AddRoleClick}
                                    /> 
                                </TableRow>
                            </Table>
                        </Card>
                    </GeneralContainer>
                )}

                {requestType === 'permissions' && (
                    <GeneralContainer>
                        <Typography variant="h2PurpleHeading" fontSize={'24px'} margin={`1rem 0 0 0`}>
                            Add New Permission to Role
                        </Typography>
        
                        <Card>
                            <Table>
                                <TableRow>
                                    <TableColumn>
                                        <Label>Role Id</Label>
                                        <InputBox
                                            type="text"
                                            id="id"
                                            value={roleId}
                                            disabled
                                        />
                                    </TableColumn>
                                </TableRow>
                                        
                                <TableRow>
                                    <TableColumn>
                                        <Label>Permission</Label>
                                        <DropdownButton onClick={toggleDropdown} isopen={isDropdownOpen.toString()}>                                            
                                            <TitleText>
                                                {displayPermission}
                                            </TitleText>

                                            <StyledDropdownIcon isopen={isDropdownOpen.toString()} />
                                            {isDropdownOpen && (
                                                <DropdownContent>
                                                    {allPermissions.map(option => (
                                                        <DropdownItem
                                                            key={option.id}
                                                            onClick={() => handleOptionClick(option)}
                                                            isSelected={selectedPermissions.some(p => p.id === option.id)}
                                                        >
                                                            {option.name} 
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownContent>
                                            )}
                                        </DropdownButton>
                                    </TableColumn>
                                </TableRow>
                                {selectedPermissions.length > 0 && (
                                    <TableRow>
                                        <TableColumn>
                                            <Label>Selected Permissions</Label>
                                            <ul>
                                                {selectedPermissions.map(permission => (
                                                    <StyledListItem key={permission.id}>
                                                        {permission.type}: {permission.name}
                                                        <StyledDeleteIcon onClick={() => removePermission(permission.id)} />
                                                    </StyledListItem>
                                                ))}
                                            </ul>
                                        </TableColumn>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <Button
                                        variant={'PinkButton'}
                                        buttonWidth={'100%'}
                                        disabled={selectedPermissions.length === 0}
                                        label={'Add Permissions to Role'}
                                        onClick={AddPermissionClick}
                                    /> 
                                </TableRow>
                            </Table>
                        </Card>
                    </GeneralContainer>
                )}
            </ModalContent>
        </>
    );
};

export default AddModal;

const StyledListItem = styled.li`
    margin-top: 10px;
`;

const GeneralContainer = styled.div`
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: ${setColor.inputText};
`;


const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const TableColumn = styled.div`
    flex: 1;
    padding: 5px;
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
`;
const InputBox = styled.input`
    padding: 10px;
    border: 1px solid ${setColor.grey1};
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    height: 50%;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    &:disabled {
        background-color: ${setColor.grey1};
    }
`;

const Card = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 15px 0;
    }
`;
const Table = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: ${setColor.passwordValidBox};
    border-radius: 5px;
    margin-top: 15px;
`;


const DropdownButton = styled.button`
    display: flex;
    font-family: 'Barlow-Regular', Helvetica;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: ${setColor.inputText};
    margin: 0rem 0rem 0rem 0rem;
    width: 100%;
    background-color: transparent;
    margin-bottom: ${(props) => (props.errorMessage || props.successMessage ? '1rem' : '0rem')};
    position: relative;
    text-align: left;
    border: ${(props) =>
        props.isopen === 'true' ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.grey1}`};
    &:disabled {
        background-color: ${setColor.grey1};
    }
    
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    font-family: 'Barlow-Regular', Helvetica;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    font-family: 'Barlow-Regular', Helvetica;
    left: 0rem;
    width: 100%;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    z-index: 1;
`;

const TitleText = styled.p`
    font-family: 'Barlow-Regular', Helvetica;
    font-weight: 500;
    color: ${(props) => (props.isSelected ? `#767676` : `${setColor.inputText}`)};
`;
const StyledDeleteIcon = styled(dustbin)`
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin: -2px 10px;
`;