import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { setColor, setFont } from '../../assets/styles/styles';
import Typography from '../../components/common/text/Typography';
import IntraverseLogoFull from '../landingPages/intraverseLandingPage/IntraverseHomeImages/IntraverseLogoFull.png';
import PinkIntraverseWithoutBorderLogo from '../../assets/images/logos/intraPinkWithoutBorder.png';
import Button from '../../components/common/button/Button';
import BackgroundContainer from '../../components/common/Backgrounds/BackgroundContainer';
import { Input } from '../../components/common/input/Input';
import Footer from '../../components/layout/footer/Footer';
import { ConfirmPasswordReset } from '../../api/UserController';
import { ReactComponent as CheckMarkIcon } from '../../assets/images/icons/checkmark-icon.svg';
import pinkCheckmark from '../../assets/images/icons/pink-checkmark.png';

const UpdatePassword = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { token } = params;
    const [loading, setLoading] = useState(false);
    const [passwordValue, setPasswordValue] = useState('');
    const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
    const [isEmailError, setIsEmailError] = useState(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isPassMatched, setIsPassMatched] = useState(false);
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const [countdown, setCountdown] = useState(5);
    const isPasswordValid = passwordValue.length >= 8;
    const hasNumber = /[0-9]/.test(passwordValue);
    const hasUpperAndLowercase = /(?=.*[A-Z])(?=.*[a-z])/.test(passwordValue);
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(passwordValue);

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setPasswordValue(value);
    };

    const handleConfirmPasswordChange = (e) => {
        const { value } = e.target;
        setConfirmPasswordValue(value);
    };

    const clearInputs = () => {
        setPasswordValue('');
        setConfirmPasswordValue('');
    };

    useEffect(() => {
        if (successMessage || isEmailError || emailErrorMsg) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                setIsEmailError(false);
                setEmailErrorMsg('');
                setIsPassMatched(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [successMessage, isEmailError, emailErrorMsg]);

    useEffect(() => {
        if (passwordResetSuccess) {
            const timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        clearInterval(timer);
                        navigate('/login');
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [passwordResetSuccess, navigate]);

    useEffect(() => {
        if (passwordValue !== '' && isPasswordValid && hasNumber && hasUpperAndLowercase && hasSpecialCharacter) {
            if (passwordValue === confirmPasswordValue) {
                return setIsPassMatched(true);
            }
        }

        setIsPassMatched(false);
    }, [passwordValue, confirmPasswordValue]);

    const validateNewPassword = async () => {
        if (passwordValue === '' || confirmPasswordValue === '') {
            setEmailErrorMsg('Password and Confirm Password should not be empty');
            return false;
        }

        if (!isPasswordValid || !hasNumber || !hasUpperAndLowercase || !hasSpecialCharacter) {
            setEmailErrorMsg('Password is not valid');
            return false;
        }

        if (!isPassMatched) {
            setEmailErrorMsg('Password does not match');
            return false;
        }

        if (isPasswordValid || hasNumber || hasUpperAndLowercase || hasSpecialCharacter || isPassMatched) {
            return true;
        }

        return false;
    };

    const clickToUpdatePassword = async () => {
        setLoading(true);
        setIsEmailError(false);
        setEmailErrorMsg('');

        if (await validateNewPassword) {
            const response = await ConfirmPasswordReset(token, passwordValue);
            if (response.success) {
                setSuccessMessage('Password updated successfully');
                setPasswordResetSuccess(true);
            } else if (!response.success) {
                setEmailErrorMsg('The new password cannot be the same as the current password.');
                setIsEmailError(true);
            } else {
                setEmailErrorMsg(
                    'Link is expired or invalid. Please try again or request a new email for password reset.'
                );
                setIsEmailError(true);
            }
            clearInputs();
            setLoading(false);
            return;
        }

        setLoading(false);
        setIsEmailError(true);
    };

    return (
        <MainContainer>
            <BackgroundContainer DiamondBackground border={'1px solid #beaeff33'}>
                <HeaderDiv>
                    <Link to="/">
                        <LoginLogo src={IntraverseLogoFull} alt="Intraverse Logo" />
                        <MobileLogo src={PinkIntraverseWithoutBorderLogo} alt="Intraverse Logo" />
                    </Link>
                    <MobileButtonContainer>
                        <Link to="/register">
                            <Button variant="OutlinedButton" label="Create Account" fontSize="12px" />
                        </Link>
                    </MobileButtonContainer>
                </HeaderDiv>
                {!passwordResetSuccess && (
                    <ContentContainer>
                        <StyledTypographyTitle variant="h2PurpleHeading" margin="0.5rem 2rem">
                            create new password
                        </StyledTypographyTitle>

                        <StyledTypographyDescription variant="ConsoleDescription" margin="1rem" align={'center'}>
                            Enter a new password associated with this account.
                        </StyledTypographyDescription>

                        <StyledForm>
                            <Input
                                variant="LoginInput"
                                type="password"
                                placeholder="Enter your password"
                                value={passwordValue}
                                onChangeHandler={handlePasswordChange}
                                maxLength={50}
                            />
                            <Card marginBottom={'1rem'}>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <StyledTd ispassvalid={isPasswordValid ? 'true' : 'false'}>
                                                <StyledCheckMarkIcon ispassvalid={isPasswordValid ? 'true' : 'false'} />
                                                At least 8 characters
                                            </StyledTd>
                                            <StyledTd ispassvalid={hasNumber ? 'true' : 'false'}>
                                                <StyledCheckMarkIcon ispassvalid={hasNumber ? 'true' : 'false'} />
                                                One number
                                            </StyledTd>
                                        </tr>
                                        <tr>
                                            <StyledTd ispassvalid={hasUpperAndLowercase ? 'true' : 'false'}>
                                                <StyledCheckMarkIcon
                                                    ispassvalid={hasUpperAndLowercase ? 'true' : 'false'}
                                                />
                                                Min. one upper and lower case
                                            </StyledTd>
                                            <StyledTd ispassvalid={hasSpecialCharacter ? 'true' : 'false'}>
                                                <StyledCheckMarkIcon
                                                    ispassvalid={hasSpecialCharacter ? 'true' : 'false'}
                                                />
                                                One special character
                                            </StyledTd>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card>
                            <Input
                                variant="LoginInput"
                                type="password"
                                placeholder="Enter your confirm password"
                                value={confirmPasswordValue}
                                onChangeHandler={handleConfirmPasswordChange}
                                maxLength={50}
                            />
                            <Card>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <StyledTd ispassvalid={isPassMatched ? 'true' : 'false'}>
                                                <StyledCheckMarkIcon ispassvalid={isPassMatched ? 'true' : 'false'} />
                                                Password matches
                                            </StyledTd>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card>
                            {successMessage && (
                                <MessageContainer color={setColor.aquaGreen} fontWeight={'700'}>
                                    {successMessage}
                                </MessageContainer>
                            )}
                            {isEmailError && <MessageContainer>{emailErrorMsg}</MessageContainer>}

                            <Button
                                disabled={!isPassMatched}
                                loading={loading}
                                variant={'PinkButton'}
                                buttonWidth={'100%'}
                                label={'change password'}
                                margin={'2rem 0 4rem 0'}
                                onClick={clickToUpdatePassword}
                            />
                        </StyledForm>
                    </ContentContainer>
                )}
                {passwordResetSuccess && (
                    <ContentContainer>
                        <PinkCheckmark src={pinkCheckmark} alt="Intraverse Logo" />
                        <Typography variant="h2PurpleHeading" margin="1rem 2rem">
                            Password Updated!
                        </Typography>
                        <Typography variant="ConsoleDescription" margin="1rem 1rem 10rem 1rem" align={'center'}>
                            You can now use your new password to sign in! Redirecting you to Intraverse in {countdown}{' '}
                            seconds...
                        </Typography>
                    </ContentContainer>
                )}
                <Footer color={setColor.iconColor} footerText={''} />
            </BackgroundContainer>
        </MainContainer>
    );
};

export default UpdatePassword;

const StyledTypographyTitle = styled(Typography)`
    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const StyledTypographyDescription = styled(Typography)`
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const MainContainer = styled.div`
    height: 100%;
    margin: 0 auto;
    padding: 20px;
    background: ${setColor.background};

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const MessageContainer = styled.div`
    line-height: 1.5;
    font-size: 1rem;
    font-family: ${setFont.barlow};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
    text-align: left;
    color: ${(props) => (props.color ? props.color : setColor.errorColor)};
    margin-left: 10px;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props.color ? props.color : setColor.inputText)};
    font-family: ${setFont.barlow};
    font-size: 16px;
    font-style: normal;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
    border-radius: 5px;
    letter-spacing: ${(props) => (props.spacing ? props.spacing : '0px')};
    background: ${setColor.passwordValidBox};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};

    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LoginLogo = styled.img`
    display: flex;
    align-self: flex-start;
    width: 250px;
    height: auto;
    margin-bottom: 30px;
    margin-left: 10px;

    @media (max-width: 768px) {
        display: none;
    }
`;

const MobileButtonContainer = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
`;

const MobileLogo = styled.img`
    display: none;
    @media (max-width: 768px) {
        display: block;
        align-self: center;
        width: 40px;
        height: auto;
        margin-bottom: 30px;
        margin-left: 0px;
    }
`;

const HeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
`;

const StyledForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    padding: 0px 20px 20px 20px;
    @media (max-width: 768px) {
        width: 90%;
    }

    @media (max-width: 480px) {
        width: 100%;
        padding: 0;
    }
`;

const LabelClickable = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: ${setColor.inputText};
    font-family: ${setFont.barlow};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    pointer: cursor;
`;

const Table = styled.table`
    width: 100%;
    color: ${setColor.iconColor};
    padding: 10px;

    @media (max-width: 768px) {
        padding: 0;
    }
`;
const StyledTd = styled.td`
    color: ${setColor.iconColor};
    text-decoration: ${(props) => (props.ispassvalid === 'true' ? 'line-through' : 'none')};
    padding: 5px 20px;
    position: relative;
    img {
        width: 20px;
        height: 20px;
    }

    @media (max-width: 768px) {
        padding: 5px;
    }
`;

const StyledCheckMarkIcon = styled(CheckMarkIcon)`
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-bottom: -2px;
    #Union {
        fill: ${(props) => (props.ispassvalid === 'false' ? setColor.grey1 : setColor.aquaGreen)};
    }
`;
const PinkCheckmark = styled.img`
    width: 100px;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        width: 80px;
        margin-top: 50px;
    }
`;
