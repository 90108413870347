import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { setColor } from '../../../../assets/styles/styles';
import Title from '../../components/Title';
import Body from '../../layout/Body';
import { formatDateTimeWithTimeZone } from '../../../../utils/generalUtils';
import { getAllRoles } from './api/rolesPermissionsController';
import { ReactComponent as ChevronIcon } from '../../../../assets/images/icons/Chevron.svg';
import Typography from '../../../../components/common/text/Typography';
import RolesPermissionsTable from './components/RolesPermissionsTable';
import { getPermissionsAndCheckAccess } from '../../../../api/RolesController';
import { CAN_MANAGE_PERMISSIONS, CAN_MANAGE_ROLES, DEFAULT_QUERY_OPTIONS } from '../../../../constants';
import { default as Button } from '../../../../components/common/button/Button';
import AddModal from './components/AddModal';
import Modal from '../../components/Modal';

const RolesPage = () => {
    const user = useSelector((state) => state.user);
    const [openModal, setOpenModal] = useState(false);
    const [updatedDateTime, setUpdatedDateTime] = useState('');
    const [createdDateTime, setCreatedDateTime] = useState('');
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const roleName = user.role;

    const { data: rolesData, error, isLoading } = useQuery(['roles'], getAllRoles,
        {
            ...DEFAULT_QUERY_OPTIONS(),
        }
    );
    const { data: canManageRoles, error: permissionsError, isLoading: permissionsLoading } = useQuery(
        ['rolePermissions', roleName],
        () => getPermissionsAndCheckAccess(roleName, CAN_MANAGE_ROLES),
        {
            enabled: !!roleName, 
            ...DEFAULT_QUERY_OPTIONS(), 
        }
    );
    const handleAddClick = () => {
        setIsModalAddOpen(true);
    }

    return (
        <>
            <CardTable>
                <TableRow>
                        <Title
                            title={'Roles & Permissions'}
                            description={
                                'Manage all roles and permissions for the Intraverse.'
                            }
                        />
                    <TableColumn>
                        <Button
                            variant={'OutlinedButton'}
                            label={'Add Role'}
                            margin={'2rem 0 0 0'}
                            onClick={() => handleAddClick()}
                        />  
                    
                    </TableColumn>
                </TableRow>
            </CardTable>

            <Body isLoading={isLoading}>
                <>
                    {isModalAddOpen && canManageRoles && (
                        <Modal
                            isOpen={isModalAddOpen}
                            setIsOpen={setIsModalAddOpen}
                            children={
                                <AddModal
                                    requestType="roles"
                                    roleId={null}
                                    closeAddModal={() => setIsModalAddOpen(false)}
                                />
                            }
                        />
                    )}
                    {rolesData && (
                        <>
                            <RolesPermissionsTable 
                            data={rolesData} 
                            requestType={'roles'} 
                            totalNumber={rolesData.length} />
                        </>
                    )} 
                 </> 
            </Body>
            
        </>
    );
};

export default RolesPage;

const CardTable = styled.div`
    display: flex;
    flex-direction: column;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;