import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { setColor } from '../../../../../assets/styles/styles';
import Title from '../../../components/Title';
import Body from '../../../layout/Body';
import { formatDateTimeWithTimeZone } from '../../../../../utils/generalUtils';
import { getRoomSettingsById } from '../api/roomController';
import { ReactComponent as ChevronIcon } from '../../../../../assets/images/icons/Chevron.svg';
import Typography from '../../../../../components/common/text/Typography';
import { default as Button } from '../../../../../components/common/button/Button';

const RoomSettingsPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [roomSettingData, setRoomSettingData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [updatedDateTime, setUpdatedDateTime] = useState('');
    const [createdDateTime, setCreatedDateTime] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const fetchData = async () => {
        try {
            
            const response = await getRoomSettingsById(id);            
            if( response.status === 200 ){
                const createdDateTime = formatDateTimeWithTimeZone(response.data.createdAt);
                const updatedDateTime = formatDateTimeWithTimeZone(response.data.updatedAt);
                response.data.createdDateTime = createdDateTime;
                response.data.updatedDateTime = updatedDateTime;
                setRoomSettingData(response.data);
            }
          
            setIsLoading(false);
            
            if(response?.response?.status === 404) {
                setErrorMessage(`${response.response.data.message} Returning to back to info page in 3 seconds...`);
                setTimeout(() => {
                    handleBack();
                    setIsLoading(true);
                }, 3000);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const handleBack = () => {
        navigate(`/console/rooms/${id}`);
    };

    return (
        <>
            <Title
                title={'ROOM SETTINGS'}
                description={
                    'Manage all rooms settings in Intraverse. Changes made here are automatically pushed to Production.'
                }
            />

            <UserConsoleText style={{ marginBottom: '1rem' }} onClick={handleBack}>
                <StyledIconPrev /> BACK TO TABLE
            </UserConsoleText>
            
            <Body isLoading={isLoading}>
                <>
                    {errorMessage && (
                        <TableRow>
                            <TableColumn>
                                <Typography variant="ConsoleDescription"><span>{errorMessage}</span></Typography>
                            </TableColumn>
                        </TableRow>
                    )}
                    {roomSettingData && (
                        <>
                            <CardTable>
                                <TableRow>
                                    <TableColumn>
                                        <Typography variant="PinkText">
                                        {roomSettingData.roomSettingsId}</Typography> 
                                    </TableColumn>

                                    <TableColumnButton>
                                        <Button
                                            label="VIEW FORMS"
                                            buttonWidth="auto"
                                            margin="0rem 1rem 0rem 0rem"
                                            // onClick={handleSettingsClick}
                                            border={`${setColor.accent4}`}
                                        />
                                    </TableColumnButton>
                                </TableRow>

                                <TableRow>
                                    <TableColumn>
                                        <strong>CREATED:</strong> {roomSettingData.createdAt}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <strong>UPDATED:</strong> {roomSettingData.updatedAt}
                                    </TableColumn>
                                </TableRow>
                            </CardTable>

                            <Typography variant="ConsoleDescription">TODO - List Room Settings Here...</Typography> 
                        </>
                     )} 
                 </> 
            </Body>
            
        </>
    );
};

export default RoomSettingsPage;

const UserConsoleText = styled.h1`
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    width: fit-content;
    color: ${setColor.accent4};
    position: relative;
    cursor: pointer;
`;

const StyledIconPrev = styled(ChevronIcon)`
    transition: transform 0.3s;
    transform: rotate(180deg);
    border-radius: 5px !important;
    margin-top: 12px !important;
    transform: rotate(90deg) !important;
`;

const CardTable = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem 1rem 1rem;
    padding: 20px 20px 20px 20px;
    color: ${setColor.inputText};
    background: ${setColor.onHoverTextcolor2};
    border-radius: 5px;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const TableColumnButton = styled.div`
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

