import React from 'react';
import styled from 'styled-components';
import { setColor } from '../../../../assets/styles/styles';
import Typography from '../../../../components/common/text/Typography';
import Quatations from '../../assets/Quotations.svg';
import { device } from '../../../../assets/styles/mediaBreakpoints';
import Button from '../../../../components/common/button/Button';

const ReviewSection = () => {
    const linkToCalendly = () => {
        window.open('https://calendly.com/intraversepro', '_blank');
    };

    return (
        <SectionBackground>
            <SectionContainer>
                <div>
                    <Typography variant="LandingPageTitle" style={{ margin: '0', fontSize: '3.2rem' }}>
                        What <Highlighted>Others</Highlighted> Are Saying
                    </Typography>
                </div>
                <GalleryDisplay>
                    <CardContainer>
                        <QuotesImage src={Quatations} alt="Quotations" />
                        <StyledTypography variant="pWhite" color="white">
                            Thank you for putting together this awesome experience for fans!
                        </StyledTypography>
                        <div>
                            <Typography
                                variant="pWhite"
                                style={{ margin: '0', fontWeight: '600', textTransform: 'uppercase' }}
                            >
                                Intraverse User
                            </Typography>
                        </div>
                    </CardContainer>
                    <CardContainer>
                        <QuotesImage src={Quatations} alt="Quotations" />
                        <StyledTypography variant="pWhite" color="white">
                            I liked the intro video and the experience graphics were incredible.
                        </StyledTypography>
                        <div>
                            <Typography
                                variant="pWhite"
                                style={{ margin: '0', fontWeight: '600', textTransform: 'uppercase' }}
                            >
                                Intraverse User
                            </Typography>
                        </div>
                    </CardContainer>
                    <CardContainer>
                        <QuotesImage src={Quatations} alt="Quotations" />
                        <StyledTypography variant="pWhite" color="white">
                            It was visually so much more appealing than other metaverse experiences.
                        </StyledTypography>
                        <div>
                            <Typography
                                variant="pWhite"
                                style={{ margin: '0', fontWeight: '600', textTransform: 'uppercase' }}
                            >
                                Intraverse User
                            </Typography>
                        </div>
                    </CardContainer>
                </GalleryDisplay>
                <Button
                    variant="VanityLinkCustomizable"
                    label="Schedule A Call"
                    onClick={() => linkToCalendly()}
                    style={{ borderRadius: '10px', width: '250px', margin: '0' }}
                />
            </SectionContainer>
        </SectionBackground>
    );
};

export default ReviewSection;

const StyledTypography = styled(Typography)`
    margin: 0;
    font-size: 19px;
    line-height: 1.4;

    @media (max-width: 960px) {
        font-size: 16px;
    }
`;

const Highlighted = styled.span`
    color: ${setColor.accent4};
`;

const SectionBackground = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #110e25;

    @media (max-width: 960px) {
        height: auto;
    }
`;

const SectionContainer = styled.div`
    max-width: 1300px;
    width: 80%;
    height: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @media ${device.desktopWide} {
        padding: 75px 0;
        gap: 50px;
    }

    @media (max-width: 960px) {
        width: 90%;
    }
`;

const GalleryDisplay = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    @media (max-width: 960px) {
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
        gap: 50px;
        margin: 50px 0;
    }
`;

const CardContainer = styled.div`
    width: 350px;
    height: 375px;
    background: #beaeff1a;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;

    @media ${device.desktopWide} {
        width: 400px;
        height: 400px;
    }

    @media (max-width: 960px) {
        width: 85%;
        height: 350px;
    }
`;

const QuotesImage = styled.img`
    height: 35px;
    width: auto;

    @media (max-width: 960px) {
        height: 30px;
    }
`;
