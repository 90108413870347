import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { getTableInfo } from '../../../../api/AdminController';
import Title from '../../components/Title';
import DataTable from '../../../../components/common/dataTable/DataTable';
import { setColor } from '../../../../assets/styles/styles';
import Button from '../../../../components/common/button/Button';
import LoadingIcon from '../../../../components/common/loading/LoadingIcon';
import { DEFAULT_QUERY_OPTIONS } from '../../../../constants';

const DynamicTable = () => {
    const { tableName } = useParams();
    const token = useSelector((state) => state?.user?.token);
    const [tableData, setTableData] = useState([]);

    const [allRecords, setAllRecords] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [primaryKey, setPrimaryKey] = useState(null);
    const [isDeletedColumn, setIsDeletedColumn] = useState('NO');
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();
    const [headers, setHeaders] = useState(Object.keys(tableData));
    const [searchInput, setSearchInput] = useState('');

    const { data, error, isLoading: dataLoading } = useQuery(
        ['tables', token, tableName], 
        () => getTableInfo(token, tableName), 
        {
            enabled: !!token && !!tableName, 
            ...DEFAULT_QUERY_OPTIONS(300000), 
        }
    );
    const openModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
        navigate(`/console/record_details?table=${tableName}&primary_key=${primaryKey}&id=${row[primaryKey]}`);
    };

    const renderRow = (record) => {
        return Object.values(record);
    };

    const fetchTableInfo = async () => {
        if(data) {
            const keys = Object.values(data.columns_data);
            let isDeletedFilter = false;
            for (let i = 0; i < keys.length; i += 1) {
                const key = keys[i];
                if (key.column_name === 'is_deleted') {
                    isDeletedFilter = true;
                    break;
                }
            }
            if (isDeletedFilter) {
                setIsDeletedColumn(true);
                const filteredRecords = await data.records.filter((record) => {
                    return record.is_deleted !== true;
                });
                setTableData(filteredRecords);
                setAllRecords(filteredRecords);
            } else {
                setTableData(data.records);
                setAllRecords(data.records);
            }
            setHeaders(keys);
            setPrimaryKey(data.primary_key);
            setIsLoading(false);
        }
    };

    const queryResults = (e) => {
        e.preventDefault();
        e.stopPropagation();
        filterResults();
    };

    const filterResults = async (e) => {
        setIsLoading(true);
        let newRecords = [];
        const columns = await headers.map((obj) => obj.column_name);
        // columns.shift()
        for (let i = 0; i < allRecords.length; i += 1) {
            if (searchInput === '') {
                newRecords = allRecords;
                break;
            }
            const record = allRecords[i];
            const idCheck = {};

            for (let j = 0; j < columns.length; j += 1) {
                const column = columns[j];
                const value = record[column];
                if (value) {
                    if (typeof value === 'string') {
                        if (value.toLowerCase().includes(searchInput.toLowerCase())) {
                            if (!idCheck[record.id]) {
                                idCheck[record.id] = true;
                                newRecords.push(record);
                            }
                        }
                    }
                }
            }
        }
        setIsLoading(false);
        setTableData(newRecords);
    };

    useEffect(() => {
        setIsLoading(true);
        fetchTableInfo();
    }, [data]);

    return (
        <>
            <Title title={`Table ${tableName}`} description={'Columns information and record on table'} />
            <InfoContainer>
                <Text>Primary Key: {primaryKey}</Text>
                <Text>Number of records: {tableData.length}</Text>
                <Text>Number of columns: {headers.length}</Text>
                <Text>IsDeleted Column: {isDeletedColumn.toString()}</Text>
            </InfoContainer>
            <Link to={`/console/tables`}>Go back to schema</Link>
            <SearchBar>
                <InputContainer>
                    <Input
                        style={{ pointerEvents: 'auto' }}
                        readonly
                        type="text"
                        placeholder={searchInput}
                        value={searchInput}
                        onChange={(e) => {
                            setSearchInput(e.target.value);
                        }}
                    />
                </InputContainer>

                <Button variant="PinkButton" label="Search" onClick={queryResults} />
            </SearchBar>
            {isLoading && (
                <LoadingDiv>
                    <LoadingIcon />
                </LoadingDiv>
            )}
            <DataTable
                headers={headers}
                data={tableData}
                renderRow={renderRow}
                openModal={openModal}
                isModalOpen={isModalOpen}
                selectedRow={selectedRow}
            />
        </>
    );
};
const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 292px); // 292px is the height of the nav bar + title component combined
    height: 100%;
    margin: 0;
`;

const Text = styled.p`
    text-align: left;
    height: 50px;
    color: ${setColor.inputText};
`;

const InfoContainer = styled.div`
    display: flex;
    grid-gap: 20px;
`;

const SearchBar = styled.div`
    display: flex;
`;

const Input = styled.input`
    flex: 1;
    border: none;
    background: none;
    width: 90%;
    height: 50px;
    color: white;
    margin-left: 8px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {

        -webkit-box-shadow: ${() => `0 0 0 30px ${setColor.purpleDarkTwo} inset !important`};
        -webkit-text-fill-color: ${() => `${setColor.white} !important`}; 
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex: 1;
    margin-right: 8px;
    border-radius: 10px;
    justify-content: center;
    background: ${setColor.purpleDark};
    cursor: pointer;
    height: 50px;
    align-items: center;
    padding: 5px;
    width: 300px;

    @media (max-width: 768px) {
        margin: 5px;
        width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin: 5px;
        width: 100%;
    }
`;

export default DynamicTable;
