import { API_POST, API_GET } from '../../../config/axiosConfig';

export const createCheckoutSession = async (token, successUrl, cancelUrl, roomName, productId) => {
    const data = {
        roomName,
        productId,
        successUrl,
        cancelUrl,
        currency: 'usd',
        quantity: 1,
    };

    try {
        const response = await API_POST(`${process.env.REACT_APP_CORE_API_URI}/v1/web/stripe/create-checkout-session`, 
            data
        );
        return response;
    } catch (error) {
        console.log('error', error);
        return error;
    }
};

export const getPaymentInfoBySessionId = async (token, checkoutSessionId, roomName, productId) => {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/v1/web/stripe/checkout-session/${roomName}/${productId}/${checkoutSessionId}`);
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const getProductInfo = async (token, productId) => {
    try {
        const response = await API_GET(`${process.env.REACT_APP_CORE_API_URI}/web/products/${productId}`);
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};
