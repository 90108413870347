import React, { useState } from 'react';
import styled from '@emotion/styled';
import { map, uniqueId } from 'lodash';
import { setFont, setColor } from '../../../assets/styles/styles';
import { ReactComponent as NotFoundIcon } from '../../../assets/images/icons/NotFound.svg';
import { ReactComponent as DropdownIcon } from '../../../assets/images/icons/Chevron.svg';

const Pagination = ({
    totalNumber,
    visibleRows,
    currentPage,
    setCurrentPage,
    totalPages,
    tag,
}) => {
    return (
        <Footer>
            <TableColumnLeft>
                Total Number of {tag}: {totalNumber}
            </TableColumnLeft>
            <TableColumnRight>
                {visibleRows.length !== 0 && (
                    <MainSection>
                        <span>
                            Page {currentPage} of {totalPages}
                        </span>
                        <PaginationButton
                            onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                            disabled={currentPage === 1}
                        >
                            Prev
                        </PaginationButton>
                        <PaginationButton
                            onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </PaginationButton>
                    </MainSection>
                )}
            </TableColumnRight>
        </Footer>
    );
};

export default Pagination;

const Footer = styled.div`
    width: 100%;
    color: ${setColor.inputText};
    display: flex;
    margin-bottom: 1rem;
`;

const TableColumnRight = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: right;
    justify-content: flex-end;
    @media (max-width: 768px) {
        font-size: 14px;
    }   
`;

const TableColumnLeft = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    @media (max-width: 768px) {
        font-size: 14px;
    }   
`;

const MainSection = styled.div`
    justify-content: flex-end;
    font-size: 14px;
    color: ${setColor.inputText}; 
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const PaginationButton = styled.button`
    border-radius: 5px;
    background: ${setColor.iconColor3};
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: ${setColor.blackColor};
    // font-family: ${setFont.barlow}, sans-serif;
    font-size: 12px;

    &:disabled {
        background: ${setColor.darkPurple};
        cursor: not-allowed;
        color: ${setColor.blackDisbaled}; 
    }
`;