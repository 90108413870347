import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { API_GET } from '../../../../config/axiosConfig';
import { setColor, setFont } from '../../../../assets/styles/styles';
import Title from '../../components/Title';
import Body from '../../layout/Body';
import { formatDateTimeWithTimeZone } from '../../../../utils/generalUtils';
import { ReactComponent as DropdownIcon } from '../../../../assets/images/icons/Chevron.svg';
import Pagination from './components/Pagination';
import RoomsTable from './components/RoomsTable';
import Banner from '../../../../components/layout/ui/Banner';
import { default as Button } from '../../../../components/common/button/Button';
import AddModal from './components/AddRoom';
import Modal from '../../components/Modal';
import { ADD_ROOM_PERMISSIONS, DEFAULT_QUERY_OPTIONS } from '../../../../constants';
import { getPermissionsAndCheckAccess } from '../../../../api/RolesController';

const fetchRoomsData = async (url, page, pageLimit) => {
    const response = await API_GET(url,
        {
            params: {
                page,
                pageLimit,
            },
        }
    );
    return response.data;
};

const RoomsPage = () => {
    const user = useSelector((state) => state.user);
    const [isDropdownOpenType, setIsDropdownOpenType] = useState(false);
    const [selectedOptionType, setSelectedOptionType] = useState('allrooms');
    const [selectedOptionTypeLabel, setSelectedOptionTypeLabel] = useState('All Rooms');
    const [totalNumber, setTotalNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredTableData, setFilteredTableData] = useState([]);
    const defaultURLSet = `${process.env.REACT_APP_CORE_API_URI}/v1/web/rooms?requestType=All`;
    const [onLoadDefaultURL, setOnLoadDefaultURL] = useState(defaultURLSet);
    const [successMessage, setSuccessMessage] = useState('');
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const dropdownOptions = ['All Rooms', 'Navigation', 'DontMiss', 'Banner', 'AllFeatured', 'Explore', 'Community'];
    const pageLimit = 5;

    const { data: roomsData, error: roomsError, isLoading: roomsLoading } = useQuery(
        ['rooms', onLoadDefaultURL, currentPage],
        () => fetchRoomsData(onLoadDefaultURL, currentPage, pageLimit),
        {
            ...DEFAULT_QUERY_OPTIONS(),
        }
    );
    const { data: hasPermissionToAddRoom, isLoading: permissionLoading } = useQuery(
        ['permissions', user.role],
        () => getPermissionsAndCheckAccess(user.role, ADD_ROOM_PERMISSIONS),
        {
            enabled: !!user.role,
            ...DEFAULT_QUERY_OPTIONS(), 
        },
    );

    useEffect(() => {
        if (roomsData) {
            const roomsCardsData = roomsData.rooms.map(room => ({
                ...room,
                createdDateTime: formatDateTimeWithTimeZone(room.createdAt),
                updatedDateTime: formatDateTimeWithTimeZone(room.updatedAt),
                isFeatured: room.isFeatured !== undefined ? room.isFeatured : null,
            }));
            roomsCardsData.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime));
            setTotalPages(roomsData.totalPages);
            setTotalNumber(roomsData.totalRoomsCount);
            setCurrentPage(roomsData.currentPage);
            setFilteredTableData(roomsCardsData || []);
        }        
    }, [roomsData]);

    useEffect(() => {
        if (roomsError) {
            setFilteredTableData([]);
        }
    }, [roomsError]);

    const applyFiltersType = async (type) => {
        const url = `${process.env.REACT_APP_CORE_API_URI}/v1/web/rooms?requestType=${type}`;
        setOnLoadDefaultURL(url);
        setCurrentPage(1);
    };

    const toggleDropdownType = () => {
        setIsDropdownOpenType(!isDropdownOpenType);
    };

    const handleOptionClickType = (type) => {
        setSelectedOptionTypeLabel(type);
        setIsDropdownOpenType(false);
        setSelectedOptionType(type);
        applyFiltersType(type);
    };

    const handleAddClick = () => {
        setIsModalAddOpen(true);
    }

    return (
        <>
            <CardTable>
                <TableRow>
                    <Title
                        title={'ROOMS OVERVIEW'}
                        description={
                            'Manage all rooms in Intraverse. Changes made here are automatically pushed to Production.'
                        }
                    />
                    {hasPermissionToAddRoom && (
                        <TableColumn>
                            <Button
                                variant={'OutlinedButton'}
                                // disabled={successMessageBanner !== ''}
                                label={'Add Room'}
                                margin={'2rem 0 0 0'}
                                onClick={() => handleAddClick()}
                            />  
                        </TableColumn>
                    )}
                </TableRow>
            </CardTable>
            
            <Body isLoading={roomsLoading || permissionLoading}>
                <>
                    <DropdownContainer>
                        <DropdownButton onClick={toggleDropdownType} isopen={isDropdownOpenType.toString()}>
                            {selectedOptionTypeLabel}
                            <StyledDropdownIcon isopen={isDropdownOpenType.toString()} />
                            {isDropdownOpenType && (
                                <DropdownContent>
                                    {dropdownOptions.map((option) => (
                                        <DropdownItem
                                            key={option}
                                            onClick={() => handleOptionClickType(option)}
                                            isSelected={selectedOptionType === option}
                                        >
                                            {option}
                                        </DropdownItem>
                                    ))}
                                </DropdownContent>
                            )}
                        </DropdownButton>

                        <PaginationContainer>
                            <Pagination
                                totalNumber={totalPages}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                                totalPages={totalPages}
                                tag={'Rooms'}
                            />
                        </PaginationContainer>
                    </DropdownContainer>
                    {isModalAddOpen && (
                        <Modal
                            isOpen={isModalAddOpen}
                            setIsOpen={setIsModalAddOpen}
                            children={
                                <AddModal
                                    closeAddModal={() => setIsModalAddOpen(false)}
                                />
                            }
                        />
                    )}   
                    <RoomsTable roomsData={filteredTableData} />
                </>
            </Body>
            <Banner
                width={'100%'}
                zIndex="1000"
                message={`${successMessage}`}
                type={'SUCCESS'}
                openBanner={!!successMessage}
                setOpenBanner={setSuccessMessage}
                topDisplay
                showCloseButton
                gameScreen={false}
                timer={8000}
                top={'78px'}
                left={'0'}
            />
        </>
    );
};

export default RoomsPage;

const DropdownContainer = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
`;

const DropdownButton = styled.button`
    position: relative;
    padding: 10px;
    background-color: TRANSPARENT;
    color: ${setColor.inputText};
    font-family: ${setFont.barlowCondensed};
    font-size: 16px;
    font-weight: 700;
    border-radius: 3px;
    margin: 1rem 1rem 0rem 0rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    border: ${(props) =>
        props.isopen === 'true' ? `1px solid ${setColor.accent4}` : `1px solid ${setColor.modalOutline}`};

    @media (max-width: 768px) {
        font-size: 13px;
    }
`;
const StyledDropdownIcon = styled(DropdownIcon)`
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    margin-left: 5px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isopen === 'true' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownItem = styled.a`
    display: block;
    padding: 10px;
    font-weight: 700;
    cursor: pointer;
    color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.inputText}`)};
    background: ${(props) => (props.isSelected ? `${setColor.pinkButtonBgGradient}` : 'transparent')};
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    border-radius: 5px;
    &:hover {
        color: ${(props) => (props.isSelected ? `${setColor.hightlight}` : `${setColor.accent4}`)};
    }
`;

const DropdownContent = styled.div`
    position: absolute;
    right: 0;
    padding: 5px;
    top: 3rem;
    left: 0rem;
    width: fit-content;
    border: 1px solid ${setColor.modalOutline};
    border-radius: 10px;
    background-color: ${setColor.buttonTextBlack};
    text-align: left;
    z-index: 1;
`;

const PaginationContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    background-color: TRANSPARENT;
    color: ${setColor.inputText};
    margin-left: auto;
    margin-top: 1rem;
`;


const CardTable = styled.div`
    display: flex;
    flex-direction: column;
`;

const TableRow = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableColumn = styled.div`
    flex: 1;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;