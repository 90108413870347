import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { dolbyTokenGenerator } from './api/StreamController';
import { setColor } from '../../assets/styles/styles';
import { hexToRGBA } from '../../utils/generalUtils';
import MicrophoneOff from '../../assets/images/icons/MicrophoneOff.svg';
import MicrophoneOn from '../../assets/images/icons/MicrophoneOn.svg';
import VideoOff from '../../assets/images/icons/VideoOff.svg';
import VideoOn from '../../assets/images/icons/VideoOn.svg';
import Chevron from '../../assets/images/icons/Chevron.svg';
import notLive from '../../assets/images/icons/notLive.svg';
import Live from '../../assets/images/icons/Live.svg';
import Typography from '../../components/common/text/Typography';

const Streaming = ({ streamId }) => {
    const [publisher, setPublisher] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const [videoHidden, setVideoHidden] = useState(false);
    const [isVideoEnabled, setIsVideoEnabled] = useState(true);
    const [isAudioEnabled, setIsAudioEnabled] = useState(true);
    const [isStreamActive, setIsStreamActive] = useState(false);
    const videoRef = useRef(null);
    const mediaStreamRef = useRef(null);

    useEffect(() => {
        const fetchPublisher = async () => {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
            if (mediaStream) {
                setMediaStream(mediaStream);
                mediaStreamRef.current = mediaStream;
            }
            const publisher = await dolbyTokenGenerator(streamId);
            if (publisher) {
                setPublisher(publisher);
            }
        };

        if (!publisher) {
            fetchPublisher();
        }

        return () => {
            if (mediaStream && mediaStreamRef.current) {
                mediaStreamRef.current.getTracks().forEach((track) => {
                    track.stop();
                });
                mediaStreamRef.current = null;
                setMediaStream(null);
            }
        };
    }, [mediaStream]);

    useEffect(() => {
        const connectMedia = async () => {
            videoRef.current.srcObject = mediaStream;
            videoRef.current.autoplay = true;
        };
        if (mediaStream && videoRef.current) {
            connectMedia();
        }
    }, [mediaStream]);

    const connectPublisher = async () => {
        try {
            const broadcastOptions = {
                mediaStream,
            };
            await publisher.connect(broadcastOptions);
            setIsStreamActive(true);
        } catch (e) {
            console.error('Connection failed, handle error', e);
        }
    };

    const hidePublisherVideo = () => {
        setVideoHidden((prev) => !prev);
    };

    const toggleAudio = () => {
        if (mediaStream) {
            mediaStream.getAudioTracks().forEach((track) => {
                track.enabled = !track.enabled;
                setIsAudioEnabled(track.enabled);
            });
        }
    };

    const toggleVideo = () => {
        if (mediaStream) {
            mediaStream.getVideoTracks().forEach((track) => {
                track.enabled = !track.enabled;
                setIsVideoEnabled(track.enabled);
            });
        }
    };

    return (
        <>
            <StreamVideoContainer>
                <StatusTag live={isStreamActive} videoHidden={videoHidden}>
                    <IconWhite src={isStreamActive ? Live : notLive} alt="status" />
                    {isStreamActive ? (
                        <Typography
                            variant="pWhite"
                            fontSize={'14px'}
                            style={{
                                margin: '0',
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                letterSpacing: '2px',
                            }}
                        >
                            Live
                        </Typography>
                    ) : (
                        <Typography
                            variant="pWhite"
                            fontSize={'14px'}
                            style={{
                                margin: '0',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                letterSpacing: '2px',
                            }}
                        >
                            Broadcast Paused
                        </Typography>
                    )}
                </StatusTag>
                {mediaStream && (
                    <PublishVideoAndToggleContainer isVideoEnabled={isVideoEnabled}>
                        <IconToggle
                            src={Chevron}
                            alt="Chevron"
                            onClick={hidePublisherVideo}
                            videoHidden={videoHidden}
                        />
                        <video
                            ref={videoRef}
                            id="streaming-video-placeholder"
                            style={{
                                objectFit: 'cover',
                                width: '275px',
                                height: '175px',
                                position: 'relative',
                                display: mediaStream && !videoHidden ? 'block' : 'none',
                                border: `2px solid ${hexToRGBA(setColor.iconColor3, 0.4)}`,
                            }}
                        >
                            <track kind="captions" srcLang="en" src="captions.vtt" label="English" default />
                            This browser does not support video playback.
                        </video>
                    </PublishVideoAndToggleContainer>
                )}

                <ButtonContainer>
                    <Button
                        disable={isAudioEnabled}
                        type="button"
                        onClick={() => {
                            toggleAudio();
                        }}
                    >
                        <Icon src={isAudioEnabled ? MicrophoneOn : MicrophoneOff} alt="microphone" />
                    </Button>
                    <Button
                        disable={isStreamActive}
                        type="button"
                        onClick={() => {
                            if (isStreamActive) {
                                publisher.stop();
                            } else {
                                connectPublisher();
                            }

                            setIsStreamActive((prev) => !prev);
                        }}
                    >
                        <Icon src={isStreamActive ? Live : notLive} alt="video" />
                    </Button>
                    <Button
                        disable={isVideoEnabled}
                        type="button"
                        onClick={() => {
                            toggleVideo();
                        }}
                    >
                        <Icon src={isVideoEnabled ? VideoOn : VideoOff} alt="video" />
                    </Button>
                </ButtonContainer>
            </StreamVideoContainer>
        </>
    );
};

export default Streaming;

Streaming.propTypes = {
    streamId: PropTypes.string,
};

const PublishVideoAndToggleContainer = styled.div`
    position: relative;
    display: ${(props) => (props.isVideoEnabled ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

const StreamVideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    position: absolute;
    bottom: 25px;
    height: fit-content;
`;

const ButtonContainer = styled.div`
    margin-top: 15px;
    padding: 8px 15px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    border: 2px solid ${hexToRGBA(setColor.iconColor3, 0.4)};
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
`;

const StatusTag = styled.div`
    padding: 5px 10px;
    background-color: ${(props) => (props.live ? '#B60C0C' : 'rgba(0, 0, 0, 0.85)')};
    border-radius: 50px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ${(props) => (props.videoHidden ? 'margin-bottom: 15px;' : 'margin-bottom: 0;')}
`;

const Button = styled.button`
    all: unset;
    background: ${(props) => (!props.disable ? setColor.errorColor : hexToRGBA(setColor.iconColor3, 0.4))};
    border-radius: 50px;
    cursor: pointer;
    padding: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
`;

const IconWhite = styled.div`
    width: 24px;
    height: 24px;
    fill: white;
    background-color: white;
    mask: url(${(props) => props.src}) no-repeat center / contain;
    -webkit-mask: url(${(props) => props.src}) no-repeat center / contain;
`;

const IconToggle = styled.img`
    position: absolute;
    z-index: 9999;
    cursor: pointer;
    background: black;
    padding: 5px;
    border-radius: 50px;
    border: 2px solid ${hexToRGBA(setColor.iconColor3, 0.4)};
    width: 30px;
    height: 30px;

    ${(props) => (props.videoHidden ? 'transform: scaleY(-1);' : 'transform: scaleY(1);')}
    ${(props) => (props.videoHidden ? 'top: -25px;' : 'top: -10px;')}
`;
