import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isGuestRole } from '../../redux/slices/users';
import Typography from '../../components/common/text/Typography';
import SelectorIntraverseLogoIcon from '../landingPages/intraverseLandingPage/IntraverseHomeImages/IntraverseLogoIcon2.png';
import { breakpoints } from '../../assets/styles/mediaBreakpoints';
import Button from '../../components/common/button/Button';

const NavigationMenu = (props) => {
    const navigate = useNavigate();
    const checkGuestRole = useSelector(isGuestRole);
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
    };
    const handleRenderStreaming = () => {
        navigate(`tz-render/tz-casino`);
    };
    return (
        <NavigationMenuContainer orientation={props.orientation}>
            <div>
                <Icon src={SelectorIntraverseLogoIcon} alt="Intraverse Logo" />
            </div>
            <NavigationTabsContainer>
                <div onClick={() => props.setSelectorScreenPage('play')}>
                    <Typography
                        variant="h1"
                        style={{ color: 'white', textTransform: 'uppercase', lineHeight: ' 0.8', cursor: 'pointer' }}
                    >
                        Play Now
                    </Typography>
                </div>
                <MenuOption>
                    {checkGuestRole ? (
                        <NavigationMenuContainer>
                            <Typography variant="h2" style={{ color: 'white' }}>
                                As a guest user, intraverse Play is not yet available to you, please create a full
                                account.
                            </Typography>
                            <Button
                                style={{ marginTop: '1rem', textAlign: 'right' }}
                                variant="PinkButton"
                                label="Logout"
                                buttonWidth={'fit-content'}
                                onClick={handleLogout}
                            />
                        </NavigationMenuContainer>
                    ) : (
                        <UnStyledLink to="/console/profile">
                            <Typography
                                variant="h1"
                                style={{ color: 'white', textTransform: 'uppercase', lineHeight: ' 0.8' }}
                            >
                                My Console
                            </Typography>
                        </UnStyledLink>
                    )}
                </MenuOption>
                {/* <MenuOption>
                <Spotify setSpotifyNotPremiumAccount={props.setSpotifyNotPremiumAccount} />
            </MenuOption> */}
            <div onClick={() => handleRenderStreaming()}>
                <Typography
                    variant="h1"
                    style={{ marginTop: '2rem', color: 'white', textTransform: 'uppercase', lineHeight: '1', cursor: 'pointer' }}
                >
                Try Render Streaming
                </Typography>
            </div>
            </NavigationTabsContainer>
            <NavigationTabsContainer>
                <div>
                    <Typography variant="p" style={{ color: 'var(--iconColor)', fontWeight: 'bold' }}>
                        VERSION {process.env.REACT_APP_VERSION}
                    </Typography>
                </div>
                <div>
                    <Typography
                        variant="p"
                        style={{
                            color: 'var(--iconColor)',
                            fontWeight: 'bold',
                            paddingTop: '5px',
                            textTransform: 'uppercase',
                        }}
                    >
                        ©{new Date().getFullYear()} TerraZero Technologies. All rights reserved.
                    </Typography>
                </div>
            </NavigationTabsContainer>
        </NavigationMenuContainer>
    );
};

export default NavigationMenu;

NavigationMenu.propTypes = {
    orientation: PropTypes.string.isRequired,
    setSelectorScreenPage: PropTypes.func.isRequired,
};

const UnStyledLink = styled(Link)`
    text-decoration: none;
`;

const NavigationMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    z-index: 5;
    height: 100%;
    width: 100%;

    ${(props) => {
        if (props.orientation && props.orientation === 'portrait') {
            return `
        padding: 25px;
        transform: rotate(-90deg);
        height:100vw;
        width: 100vh;
      `;
        }
        return `
        transform: none;
        padding: none;
        height: 100%;
        width: 100%;
      `;
    }}
`;

const NavigationTabsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
`;

const MenuOption = styled.div`
    margin-top: 50px;

    @media (max-width: ${breakpoints.tablet}px), (max-height: ${breakpoints.mobile}px) {
        margin-top: 30px;
    }
`;

const Icon = styled.img`
    width: 80px;
    height: 80px;

    @media (max-width: ${breakpoints.tablet}px), (max-height: ${breakpoints.mobile}px) {
        width: 50px;
        height: 50px;
    }
`;
